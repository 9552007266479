import styled from "@emotion/styled";
import { Button } from "react-aria-components";

export const CalendarContainer = styled.div`
  label: CalendarContainer;
  padding: 1.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const CalendarTitle = styled.span`
  color: var(--Greys-Charcoal, #212121);
  text-align: center;
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.36px;
  position: relative;
`;

export const StyledButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 2.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2.5px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
    border: 2.5px solid transparent;
  }

  &[data-dir="prev"] {
    transform: rotate(180deg);
  }
`;

export const CalendarTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: var(--calendar-cell-gap, 0.5rem);
`;

export const CalendarCellHeader = styled.div`
  color: var(--Greys-Charcoal80, rgba(33, 33, 33, 0.8));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
`;

export const Cell = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  border-radius: 50%;
  line-height: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.1s ease-in-out;
  transition-property: opacity, background, box-shadow;
  color: var(--Greys-Charcoal, #212121);
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.28px;
  opacity: 0;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      89.59deg,
      #80aeff 0.36%,
      #f7bde6 52.71%,
      #ffbd70 99.65%
    );
    pointer-events: none;
    z-index: -1;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 2.5px;
    background: inherit;
    pointer-events: none;
    z-index: -1;
    border-radius: inherit;
  }

  &.available {
    background: var(--Primary-Cream, #fffcf3);
    opacity: 1;

    @media (pointer: fine) {
      &:hover {
        background: var(--Greys-White, #fff);
        box-shadow: var(--light-shadow);
      }
    }
  }

  &.today {
    &:not(.selected) {
      background: var(--color-gray-fog, #f4f4f4);
    }

    font-weight: 500;
  }

  &.selected {
    background: var(--Greys-White, #fff);
    box-shadow: var(--large-shadow);
    z-index: 1;

    &::before {
      opacity: 1;
    }

    &::after {
      background: white;
    }
  }

  &.disabled,
  &.unavailable {
    color: var(--Greys-Charcoal60, rgba(33, 33, 33, 0.6));
    text-align: center;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    text-decoration: line-through;
    opacity: 0.75;
    pointer-events: none;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  outline: none;

  * {
    outline: none;
  }
`;

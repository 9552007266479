import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import React, { useRef } from "react";
import { z } from "zod";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";

const DebugLoadingOverlay = () => {
  const schema = z.object({
    title: z.string().optional(),
    message: z.string().optional()
  });

  const o = useRef<z.infer<typeof schema>>({});

  return (
    <div>
      <AutoForm
        schema={schema}
        onChange={(key, value) => {
          o.current[key] = value;
        }}
      >
        <div style={{ padding: "1rem" }}>
          <AutoFormInputField name="title" label="Title" />
          <AutoFormInputField name="message" label="Message" />
        </div>
      </AutoForm>
      <div
        style={{
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap"
        }}
      >
        <Button
          onPress={() => {
            BlockingLoadingOverlayController.startLoading({
              bg: "transparent",
              title: o.current.title,
              message: o.current.message
            });
          }}
        >
          Start Loading
        </Button>
        <Button
          onPress={() => {
            BlockingLoadingOverlayController.loadingSuccess({
              title: o.current.title,
              message: o.current.message
            });
          }}
        >
          Success
        </Button>
        <Button
          onPress={() => {
            BlockingLoadingOverlayController.loadingError({
              title: o.current.title,
              message: o.current.message
            });
          }}
        >
          Error
        </Button>
        <Button onPress={() => BlockingLoadingOverlayController.endLoading()}>
          End Loading
        </Button>
      </div>
    </div>
  );
};

export default DebugLoadingOverlay;

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Scheduler from "molecule/scheduler/Scheduler";
import { useBloc } from "@blac/react";
import {
  ScheduleAppointmentTypes,
  SchedulerBloc
} from "molecule/scheduler/SchedulerBloc";
import Loader from "../Loader/Loader";
import styled from "@emotion/styled";
import { getLocalTimeZone } from "@internationalized/date";
import { IonButton, IonModal } from "@ionic/react";
import { IconCloseCircle } from "src/constants/icons";
import Translate from "../Translate/Translate";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import reportErrorSentry from "src/lib/reportErrorSentry";
import Avatar from "../Avatar/Avatar";
import { Blac } from "blac-next";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import translate from "src/lib/translate";
import AppointmentIcon from "../AppointmentIcon/AppointmentIcon";
import { TranslationKey } from "src/types/translationKey";
import { AppointmentResponse } from "@9amhealth/openapi";
import { AppQueryPopupsController } from "../AppQueryPopups/AppQueryPopupsBloc";
import { Button } from "@9amhealth/shared";
import { userPreferences } from "src/state/state";

const Wrap = styled.div`
  padding: 0 1.6rem;
  margin: 0 auto 5rem;
  width: min(100%, 520px);
`;

const ErrorInfo = styled.div`
  margin: 0 auto;
  width: min(100%, 520px);
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  h4 {
    text-wrap: balance;
  }
`;

const Heading = styled.div`
  text-align: center;
  margin: 2rem 0 3rem;
  scroll-margin-top: 100vh;

  .appointment-icon svg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    display: block;
    margin: 0 auto;
  }

  h2 {
    font-size: 2.25rem;
    margin-top: 2rem;
    text-wrap: pretty;
  }

  p {
    font-size: 1;
    margin-top: 0.8rem;
    padding: 0 1rem;
  }

  .slot-details {
    margin: 2rem 0;
    align-items: center;
  }
`;

const AvailabilitySection = styled.div`
  scroll-margin-top: 2rem;
  margin: 5rem 0;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-family: var(--font-family);
  }
`;

const StyledSchedulerConfirmSlot = styled.div`
  min-height: 100%;
`;

const AppointmentScheduler: FC<{
  type?: ScheduleAppointmentTypes;
  rescheduleId?: string;
  onScheduled?: () => void;
}> = ({ type, onScheduled, rescheduleId }) => {
  const [
    {
      loading,
      selectedDate,
      availableSlotGroups,
      selectedSlot,
      selectedSlotGroup
    },
    { setSelectedSlot, bookSelectedSlot }
  ] = useBloc(SchedulerBloc);

  const [appointmentType, setAppointmentType] = useState<
    ScheduleAppointmentTypes | undefined
  >(type);
  const [rescheduleAppointment, setRescheduleAppointment] = useState<
    AppointmentResponse | undefined
  >();
  const [error, setError] = useState<TranslationKey | undefined>();

  const availabilitySectionRef =
    useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
  const headingRef =
    useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
  const slotProviderSectionRef =
    useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;

  useEffect(() => {
    setTimeout(() => {
      if (headingRef.current) {
        headingRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 60);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (availabilitySectionRef.current) {
        availabilitySectionRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 60);
  }, [selectedDate]);

  useEffect(() => {
    setTimeout(() => {
      if (slotProviderSectionRef.current) {
        slotProviderSectionRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 60);
  }, [selectedSlotGroup]);

  const handleDismissSlotDetailModal = () => {
    setSelectedSlot(undefined);
  };

  const handleBookAppointment = () => {
    BlockingLoadingOverlayController.startLoading({
      bg: "transparent",
      title: "appointment.loading.booking",
      message: "appointment.loading.booking.description"
    });

    void bookSelectedSlot({
      rescheduleAppointment
    })
      .then((data) => {
        Blac.getBloc(AppointmentsBloc).addAppointment(data);
        if (onScheduled) {
          onScheduled();
        }
        BlockingLoadingOverlayController.loadingSuccess({
          title: "appointment.loading.success",
          message: translate("appointment.loading.success.description", {
            name: userPreferences.firstName
          })
        });
      })
      .catch((e) => {
        reportErrorSentry(e);
        BlockingLoadingOverlayController.loadingError({
          title: "Error",
          retry: () => {
            handleBookAppointment();
          }
        });
      });
  };

  useEffect(() => {
    let mounted = true;
    if (rescheduleId && !appointmentType && !rescheduleAppointment) {
      Blac.getBloc(AppointmentsBloc)
        .getAppointmentById(rescheduleId)
        .then((appointment) => {
          if (!mounted) return;
          if (!appointment) {
            setError("error_appointment_details_load_failed");
            return;
          }
          setAppointmentType(appointment.type);
          setRescheduleAppointment(appointment);
        })
        .catch((e) => {
          reportErrorSentry(e);
        });
    }
    if (!rescheduleId && !appointmentType) {
      setError("error_appointment_details_load_failed");
    }

    return () => {
      mounted = false;
    };
  }, [rescheduleId, appointmentType, rescheduleAppointment]);

  const patricipantsNames = useMemo(() => {
    if (!rescheduleAppointment) return [];
    return rescheduleAppointment.participants
      .map((p) => p.displayName)
      .filter(Boolean);
  }, [rescheduleAppointment]);

  if (error) {
    return (
      <ErrorInfo>
        <h4>{translate(error)}</h4>
        <Button
          hideArrow
          theme="charcoal"
          onPress={(): void => AppQueryPopupsController.closePopup()}
        >
          <Translate msg="close" />
        </Button>
      </ErrorInfo>
    );
  }

  if (!appointmentType) {
    return (
      <Loader active={true} fullPage bg="transparent" fixed hideProgress />
    );
  }

  return (
    <Wrap>
      <Heading ref={headingRef}>
        <div className="appointment-icon">
          <AppointmentIcon type={appointmentType} />
        </div>
        <h2>
          {translate("appointment.title", {
            context: rescheduleId
              ? patricipantsNames.length > 0
                ? "rescheduleNamed"
                : "reschedule"
              : "schedule",
            name: translate("appointment.name", {
              context: appointmentType.toLowerCase().replace("_", "-")
            }),
            participants: patricipantsNames
          })}
        </h2>
        <p>
          {translate("appointment.schedule.description", {
            context: rescheduleId ? "reschedule" : "schedule"
          })}
        </p>
      </Heading>
      <Loader active={loading}>
        <Scheduler
          view="pick-date"
          type={appointmentType}
          rescheduleAppointment={rescheduleAppointment}
        />

        {selectedDate && availableSlotGroups.length > 0 && (
          <AvailabilitySection ref={availabilitySectionRef}>
            <h3>
              <Translate
                msg="appointment.schedule.available_on"
                variables={{
                  date: selectedDate
                    .toDate(getLocalTimeZone())
                    .toLocaleDateString("en-us", {
                      month: "short",
                      weekday: "short",
                      day: "numeric",
                      year: "numeric"
                    })
                }}
              />
            </h3>
            <Scheduler view="pick-slot" />
          </AvailabilitySection>
        )}

        {selectedSlotGroup && !rescheduleAppointment && (
          <AvailabilitySection ref={slotProviderSectionRef}>
            <h3>
              <Translate msg="appointment.schedule.select_provider" />
            </h3>
            <Scheduler
              view="pick-provider"
              avatar={(id) => <Avatar id={id} />}
            />
          </AvailabilitySection>
        )}

        <IonModal
          isOpen={Boolean(selectedSlot)}
          onDidDismiss={handleDismissSlotDetailModal}
          initialBreakpoint={0.7}
          breakpoints={[0, 0.7]}
          className="selected-search-result-info"
        >
          <IonButton
            aria-label="Close"
            className="close-modal-button"
            onClick={handleDismissSlotDetailModal}
          >
            <IconCloseCircle />
          </IonButton>

          <StyledSchedulerConfirmSlot>
            <Scheduler
              view="confirm-slot"
              avatar={(id) => <Avatar id={id} />}
              handleBookAppointment={handleBookAppointment}
              rescheduleAppointment={rescheduleAppointment}
              bookAppointmentText={
                rescheduleAppointment ? (
                  <Translate msg="action.rescheduleAppointment" />
                ) : (
                  <Translate msg="action.bookAppointment" />
                )
              }
              scheduleInfo={
                <p>
                  <Translate msg="appointment.info.cancellation.policy" />
                </p>
              }
            />
          </StyledSchedulerConfirmSlot>
        </IonModal>
      </Loader>
    </Wrap>
  );
};

export default AppointmentScheduler;

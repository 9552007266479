import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import type Path from "src/constants/path";

const MobileNavPosition = styled.nav`
  label: MobileNavPosition;
  position: fixed;
  --link-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  background: var(--color-cream);
  background: var(--greys-light-gray, #e6e3db);
  padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 0px);

  &[data-page="chat"] {
    background: transparent;
  }

  @media (min-width: ${APP_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileNav: FC<{
  children: React.ReactNode;
  page: Path;
}> = ({ children, page }) => {
  return (
    <MobileNavPosition data-page={page} id="bottom">
      {children}
    </MobileNavPosition>
  );
};

export default MobileNav;

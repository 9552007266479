import { css } from "@emotion/css";
import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import InDialog from "src/ui/components/InDialog/InDialog";
import AppointmentScheduler from "../../AppointmentScheduler/AppointmentScheduler";
import { useSearchParams } from "react-router-dom";
import { ScheduleAppointmentTypes } from "molecule/scheduler/SchedulerBloc";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";

const ionModalStyles = css`
  // popup options for iframe
  ion-content {
    --padding-start: 0;
    --padding-end: 0;
  }
`;

const ScheduleAppointmentDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [searchParams] = useSearchParams();
  const rescheduleId = searchParams.get("reschedule") as string | undefined;
  const appointmentType = searchParams.get("type") as
    | ScheduleAppointmentTypes
    | undefined;

  const onClose = () => {
    AppQueryPopupsController.closePopup();
  };

  return (
    <InDialog
      popup
      title={translate(
        rescheduleId
          ? "title.rescheduleAppointment"
          : "title.scheduleAppointment"
      )}
      onClose={onClose}
      returnUrl={returnUrl}
      className={ionModalStyles}
      width="min(calc(100% - 4rem), 520px)"
      height="min(calc(100% - 4rem), 80vh)"
      background="gradient"
    >
      <AppointmentScheduler
        type={appointmentType}
        rescheduleId={rescheduleId}
        onScheduled={onClose}
      />
    </InDialog>
  );
};

export default ScheduleAppointmentDialog;

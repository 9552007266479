import styled from "@emotion/styled";
import type { SanityImageAssetDocument } from "@sanity/client";
import type { CSSProperties, FC } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import { CmsImageRootObject } from "src/types/sanitySchema";

const ImageWrapper = styled.div`
  label: ImageWrapper;
  overflow: hidden;
  aspect-ratio: var(--img-aspect);
  border-radius: var(--border-radius);
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
  max-height: 100%;
  min-width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    background-size: cover;
    background-position: center var(--img-y);
    background-image: var(--img-blurred);
    transition: opacity calc(var(--ease-time) * 2) var(--ease-type);
  }

  &[data-loaded="true"]::before {
    opacity: 0;
  }

  &[data-fill="true"] {
    position: absolute;
    inset: 0;
    z-index: 1;
    aspect-ratio: unset;
  }
`;

const AspectBlurImage = styled.img`
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  object-position: center var(--img-y);
  inset: 0;
`;

interface MinimalSanityImageAssetDocument {
  url?: string;
  metadata?: {
    dimensions?: {
      aspectRatio?: number;
    };
    lqip?: string;
  };
}

export function imageFromSrc(src: string): SanityImageAssetDocument {
  return {
    url: src,
    metadata: {
      dimensions: {},
      lqip: ""
    }
  } satisfies MinimalSanityImageAssetDocument as SanityImageAssetDocument;
}

const ResponsiveImage: FC<{
  image?: SanityImageAssetDocument | CmsImageRootObject;
  alt?: string;
  q?: number;
  fit?: string;
  initialAspectRatio?: number;
  customAspectRatio?: number;
  startWidth?: number;
  fillContainer?: boolean;
  imgY?: number;
  className?: string;
}> = ({
  image,
  q = 90,
  fit = "max",
  initialAspectRatio = 1.78,
  customAspectRatio = undefined,
  startWidth,
  fillContainer = false,
  imgY = 0.5,
  className = ""
}) => {
  const src = image?.url;
  const blur = image?.metadata.lqip;
  const [loaded, setLoaded] = React.useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [useWidth, setUseWidth] = React.useState<null | number>(null);
  const checkCount = useRef(0);

  const detectWidth = useCallback(() => {
    checkCount.current++;
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();
      if (width === 0) {
        if (checkCount.current > 5) {
          setUseWidth(1);
          return;
        }
        setTimeout(() => {
          detectWidth();
        }, 50);
      } else {
        setUseWidth(width);
      }
    }
  }, [ref]);

  useEffect(() => {
    if (startWidth) {
      setUseWidth(startWidth);
      return;
    }
    if (ref.current) {
      detectWidth();
    }
  }, [ref, startWidth]);

  const aspectRatio =
    customAspectRatio ??
    image?.metadata.dimensions.aspectRatio ??
    initialAspectRatio;

  const scale = (): number => {
    if (!useWidth) {
      return 0;
    }
    return Math.ceil(useWidth * window.devicePixelRatio);
  };

  const defaultSrc =
    src && useWidth ? `${src}?w=${scale()}&q=${q}&auto=format&fit=${fit}` : "";

  useEffect(() => {
    if (loaded) {
      return;
    }
    if (defaultSrc && imageRef.current) {
      const ld = (useSrc: string): void => {
        const img = new Image();
        img.src = useSrc;
        img.onload = () => {
          setLoaded(true);
        };
      };

      const check = (): boolean => {
        const current = imageRef.current?.currentSrc ?? "";
        const hasSrc = Boolean(
          current && !current.includes(window.location.host)
        );
        if (hasSrc) {
          ld(current);
        }
        return hasSrc;
      };

      let success = check();
      if (!success) {
        const i = setInterval(() => {
          success = check();
          if (success) {
            clearInterval(i);
          }
        }, 50);
      }
    }
  }, [defaultSrc, imageRef, loaded]);

  return (
    <ImageWrapper
      ref={ref}
      style={
        {
          "--img-aspect": aspectRatio,
          "--img-blurred": blur ? `url(${blur})` : undefined,
          "--img-y": `${100 * imgY}%`
        } as CSSProperties
      }
      className={`responsive-image ${className}`}
      data-loaded={loaded}
      data-fill={fillContainer}
    >
      {defaultSrc && (
        <AspectBlurImage
          ref={imageRef}
          //srcSet={useWidth ? srcSet : ""}
          //sizes={sizes}
          src={useWidth ? defaultSrc : ""}
        />
      )}
    </ImageWrapper>
  );
};

export default ResponsiveImage;

import { FeatureResponse, FeaturesControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import sanityClient from "src/sanityClient";
import envVariables from "./envVariables";
import reportErrorSentry from "./reportErrorSentry";
import sanityQuery from "./sanityQuery";

const featureFlagPrefix = "featureFlag_";

export enum FeatureFlagName {
  loggingOtaUpdates = "loggingOtaUpdates",
  customerIoInDevMode = "customerIoInDevMode",
  demoAppAccess = "demoAppAccess",
  disableQuestionnaireCache = "disableQuestionnaireCache",
  verboseLogging = "verboseLogging",
  loggingApiCache = "loggingApiCache",
  loggingSentryBreadcrumbs = "loggingSentryBreadcrumbs",
  loggingTrackingEvents = "loggingTrackingEvents",
  loggingWebsocket = "loggingWebsocket",
  loggingTaskManagement = "loggingTaskManagement",
  showAllTasks = "showAllTasks",
  loggingAuthenticationManager = "loggingAuthenticationManager",
  loggingUserState = "loggingUserState",
  translations = "TRANSLATIONS",
  loggingTranslation = "loggingTranslation",
  chatEvents = "chatEvents",
  loggingBiometricsVerification = "loggingBiometricsVerification",
  /** Show the questionnaire variables in each questionnaire step */
  showQuestionnaireVariables = "showQuestionnaireVariables",
  enableInHouseReschedulingUI = "enableInHouseReschedulingUI"
}

type KnownDynamicFlags =
  | "sso_login_option_transcarent"
  | "rpm_show_progress_floating_indicator"
  | "disable_scheduling_for_onboarding_initial_visit_rd"
  | "disable_scheduling_for_onboarding_initial_visit_pharmacist"
  | "disable_scheduling_for_onboarding_initial_visit_md"
  | "disable_request_callback_onboarding_call";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DynamicFeatureFlagName = [
  "flags_global",
  "flags_android",
  "flags_ios",
  "flags_web",
  "flags_develop",
  "flags_demo",
  "flags_production",
  "flags_dev_android",
  "flags_dev_ios",
  "flags_dev_web",
  "flags_qa_android",
  "flags_qa_web",
  "flags_qa_ios",
  "flags_prod_android",
  "flags_prod_web",
  "flags_prod_ios"
] as const;

type DynamicFeatureFlagsName = (typeof DynamicFeatureFlagName)[number];

type DynamicFeatureFlags = {
  title: KnownDynamicFlags;
  value: boolean;
}[];

type FeatureFlagsState = Record<FeatureFlagName | string, boolean | undefined>;

export class FeatureFlags extends Cubit<FeatureFlagsState> {
  flagsLoaded = false;
  static initialFlags: Record<FeatureFlagName | string, false> = {
    [FeatureFlagName.loggingOtaUpdates]: false,
    [FeatureFlagName.customerIoInDevMode]: false,
    [FeatureFlagName.demoAppAccess]: false,
    [FeatureFlagName.disableQuestionnaireCache]: false,
    [FeatureFlagName.verboseLogging]: false,
    [FeatureFlagName.loggingApiCache]: false,
    [FeatureFlagName.loggingSentryBreadcrumbs]: false,
    [FeatureFlagName.loggingTrackingEvents]: false,
    [FeatureFlagName.loggingWebsocket]: false,
    [FeatureFlagName.loggingTaskManagement]: false,
    [FeatureFlagName.showAllTasks]: false,
    [FeatureFlagName.loggingAuthenticationManager]: false,
    [FeatureFlagName.loggingUserState]: false,
    [FeatureFlagName.loggingTranslation]: false,
    [FeatureFlagName.chatEvents]: false,
    [FeatureFlagName.loggingBiometricsVerification]: false,
    [FeatureFlagName.showQuestionnaireVariables]: false,
    [FeatureFlagName.enableInHouseReschedulingUI]: false
  };

  availableFlags: FeatureFlagName[] = Object.keys(
    FeatureFlags.initialFlags
  ) as FeatureFlagName[];

  flags: Record<
    KnownDynamicFlags | FeatureFlagName | FeatureResponse.feature | string,
    boolean | undefined
  > = {
    ...FeatureFlags.initialFlags
  };

  constructor() {
    super({ ...FeatureFlags.initialFlags });
    const sessionFlags = Object.keys(sessionStorage).reduce(
      (acc, key) => ({
        ...acc,
        [key]: sessionStorage.getItem(`${featureFlagPrefix}${key}`) === "true"
      }),
      {}
    );

    this.flags = {
      ...this.flags,
      ...sessionFlags
    };

    void this.loadDynamicFlags();
  }

  getFlag = (
    name: KnownDynamicFlags | FeatureFlagName | FeatureResponse.feature
  ): boolean => {
    const value = sessionStorage.getItem(`${featureFlagPrefix}${name}`);
    if (value) {
      this.flags[name] = value === "true";
    }

    return Boolean(this.flags[name]);
  };

  getRemoteFlag(name: FeatureResponse.feature): boolean | undefined {
    return this.flags[name];
  }

  __unsafe__setRemoteFlag = (name: FeatureResponse.feature, value: boolean) => {
    // eslint-disable-next-line no-console
    console.warn("AVOID SETTING REMOTE FLAGS");
    this.flags[name] = value;
    this.setState();
  };

  setFlag(name: FeatureFlagName, value: boolean): void {
    this.flags[name] = value;
    sessionStorage.setItem(`${featureFlagPrefix}${name}`, value.toString());
    this.setState();
  }

  toggle = (name: FeatureFlagName): void => {
    const current = this.getFlag(name);
    this.setFlag(name, !current);
  };

  reset(): void {
    this.flags = { ...FeatureFlags.initialFlags };
    this.setState();
  }

  get appAccess(): boolean {
    return this.getFlag(FeatureFlagName.demoAppAccess);
  }

  get disableQuestionnaireCache(): boolean {
    return this.getFlag(FeatureFlagName.disableQuestionnaireCache);
  }

  get showAllTasks(): boolean {
    return this.getFlag(FeatureFlagName.showAllTasks);
  }

  loadBackendFlags = async () => {
    if (this.backendFlagsLoaded) {
      return;
    }
    try {
      const result = await FeaturesControllerService.listFeatures();
      const flags = result.data;

      flags.forEach((flag) => {
        const name = flag.feature;
        const enabled = flag.status === FeatureResponse.status.ACTIVE;

        this.flags[name] = enabled;
      });

      this.setState();
      this.backendFlagsLoaded = true;
    } catch (e) {
      reportErrorSentry(e);
    }
  };
  backendFlagsLoaded = false;

  loadDynamicFlags = async () => {
    try {
      const response = await sanityClient.fetch<
        Record<DynamicFeatureFlagsName, DynamicFeatureFlags | undefined>[]
      >(sanityQuery.featureFlags());
      const [flags] = response;

      const envName = envVariables.ENV_NAME;
      const expandEnv: Record<string, string | undefined> = {
        dev: "develop",
        qa: "demo",
        prod: "production"
      } as const;
      const [env, platform] = envName.split("-");

      const useFlags = [
        "flags_global",
        `flags_${expandEnv[env] ?? "develop"}`,
        `flags_${platform}`,
        `flags_${envVariables.ENV_NAME.replace(/-/g, "_").replace("local", "dev")}`
      ] as DynamicFeatureFlagsName[];

      const dynFlags: Record<string, boolean> = {};

      for (const useFlag of useFlags) {
        const flagsSet = flags[useFlag] ?? [];
        for (const fs of flagsSet) {
          dynFlags[fs.title] = fs.value;
        }
      }
      this.flags = { ...this.flags, ...dynFlags };
      this.setState();
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  setState = () => {
    this.emit({ ...this.flags });
  };

  get flagMultiFactorAuth(): boolean | undefined {
    return this.getRemoteFlag(FeatureResponse.feature.END_USER_MFA_TOTP);
  }

  get translationActive(): boolean {
    return Boolean(this.state[FeatureFlagName.translations]);
  }

  get disableRequestCallbackOnboardingCall(): boolean {
    return this.getFlag("disable_request_callback_onboarding_call");
  }

  get enableInHouseReschedulingUI(): boolean {
    return this.getFlag(FeatureFlagName.enableInHouseReschedulingUI);
  }
}

export const featureFlags = new FeatureFlags();

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
(window.Set as any).featureFlags = featureFlags;

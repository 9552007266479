enum Path {
  root = "/",
  join = "join",
  joinCoupon = "code/:coupon",
  signupQuestionnaire = "questionnaire",
  questionnairePreview = "questionnaire/preview",
  questionnaireLifestylePreview = "questionnaire/lifestyle/preview",
  standaloneQuestionnaire = "questionnaire/standalone",
  questionnaireIframe = "questionnaire/iframe",
  auth = "auth",
  authLogin = "login",
  authRegister = "register",
  authRegisterAlternative = "signup",
  authRegisterAlternativeCoupon = "signup/:coupon",
  authOneTimeToken = "ott",
  authResetPassword = "reset-password",
  app = "app",
  messages = "messages",
  chat = "chat",
  myPlan = "plan",
  labResults = "lab-results",
  profile = "profile",
  confirmEmail = "email/verify",
  actionDone = "action-done",
  signupCustom = "signup/:campaign?/:step?",
  signupCustomDemo = "demo/signup/:campaign?/:step?",
  signupCustomDebug = "debug/signup/:campaign?/:step?",
  program = "program/:program/:module?/:task?",
  downloadApp = "download-app",
  individuals = "individuals",
  handover = "handover",
  home = "home"
}

export default Path;

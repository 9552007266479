import { Cubit } from "blac-next";

export default class TranslationBloc extends Cubit<null> {
  static appTranslateFn: (
    key: string,
    variables: Record<string, unknown>
  ) => string = (key: string, variables?: Record<string, unknown>) => {
    // eslint-disable-next-line no-console
    console.error("Not implemented", { key, variables });
    throw new Error("Not implemented");
  };

  static translate = (
    key: string,
    /**
     * If string, it will be used as the context
     * If object, it will be used as variables
     */
    variables: string | Record<string, unknown> = {}
  ) => {
    const varObj =
      typeof variables === "string"
        ? {
            context: variables
          }
        : variables;

    return TranslationBloc.appTranslateFn(key, varObj);
  };

  static setTranslateFn = (
    fn: (key: string, variables: Record<string, unknown>) => string
  ) => {
    TranslationBloc.appTranslateFn = fn;
  };

  translations: Record<string, string> = {};
}

export const { translate } = TranslationBloc;

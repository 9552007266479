import React from "react";

export default function IconCalendarSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".6"
        strokeWidth="1.5"
        d="M17.5 8.33h-15m10.83-6.66V5M6.67 1.67V5M6.5 18.33h7c1.4 0 2.1 0 2.64-.27a2.5 2.5 0 0 0 1.09-1.1c.27-.53.27-1.23.27-2.63v-7c0-1.4 0-2.1-.27-2.63a2.5 2.5 0 0 0-1.1-1.1c-.53-.27-1.23-.27-2.63-.27h-7c-1.4 0-2.1 0-2.63.28a2.5 2.5 0 0 0-1.1 1.09c-.27.53-.27 1.23-.27 2.63v7c0 1.4 0 2.1.27 2.64.24.47.62.85 1.1 1.1.53.26 1.23.26 2.63.26Z"
      />
    </svg>
  );
}

/* eslint-disable no-useless-escape */
export const APP_LINK_URLS: string[] = [
  "https://app.join9am.com",
  "https://app.dev.join9am.com",
  "https://app.qa.join9am.com",
  "https://app.9am.health",
  "https://app.dev.9am.health",
  "https://app.qa.9am.health",
  location.origin
].map((url) => new URL(url).hostname);

export const CMS_API_LINK_URLS: string[] = [
  "https://cms.join9am.com",
  "https://sanity-for-9am.vercel.app"
].map((url) => new URL(url).hostname);

export const WEBSITE_LINK_URLS: string[] = [
  "https://join9am.com",
].map((url) => new URL(url).hostname);

export const API_LINK_URLS: string[] = [
  "https://api.us-east.prod.join9am.com",
  "https://api.dev.join9am.com",
  "https://api.qa.join9am.com",
  "https://api.us-east.prod.9am.health",
  "https://api.dev.9am.health",
  "https://api.qa.9am.health"
].map((url) => new URL(url).hostname);

export const APERO_LINK_URLS: string[] = [
  "https://9am-health-test.aperohealth.com",
  "https://9am-health.aperohealth.com"
].map((url) => new URL(url).hostname);

export const URL_REGEX_FULL =
  /https?:\/\/[a-zA-Z0-9\/\$\%\-\_\.\+\!\*\';\?\:\@\=\&\#?]+/g;

const checkLink = (link: string | URL, urls: string[]): boolean => {
  if (!link) return false;

  try {
    const url = new URL(link);
    return urls.some((appLinkUrl) => {
      return url.hostname === appLinkUrl;
    });
  } catch (e: unknown) {
    if (link.toString().startsWith("#")) return false;
    if (link.toString().startsWith("/app")) return false;

    // eslint-disable-next-line no-console
    console.error(`Error checking link: ${link}`);
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }
};

export const isAppLink = (link: string | URL): boolean => {
  return checkLink(link, APP_LINK_URLS);
};

export const isCmsApiLink = (link: string | URL): boolean => {
  return checkLink(link, CMS_API_LINK_URLS);
};

export const isApiLink = (link: string | URL): boolean => {
  return checkLink(link, API_LINK_URLS);
};

export const isAperoLink = (link: string | URL): boolean => {
  return checkLink(link, APERO_LINK_URLS);
};

export const isBlogLink= (link: string | URL): boolean => {
  return checkLink(link, WEBSITE_LINK_URLS);
};

export const toAppIfamePopup = (url: URL): URL => {
  const newUrl = new URL(window.location.pathname, window.location.origin);

  newUrl.searchParams.set("popup", "iframe");
  newUrl.searchParams.set("title", "Appointment");
  newUrl.searchParams.set("url", encodeURIComponent(url.href));

  return newUrl;
};

export function rewriteAppLink(link?: string): string {
  if (!link) return "";
  if (link.startsWith("#")) return link;

  try {
    let url = new URL(link, window.location.origin);

    if (isAperoLink(url)) {
      url = toAppIfamePopup(url);
    }

    // remove stay=false from url
    url.searchParams.delete("stay");

    if (isAppLink(url)) {
      return url.pathname + url.search + url.hash;
    }

    return url.href;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error rewriting link: ${link}`, e);
    return "";
  }
}

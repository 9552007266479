/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentParticipantResponse } from './AppointmentParticipantResponse';

/**
 * Represents a single appointment.
 */
export type AppointmentResponse = {
    /**
     * The appointment ID.
     */
    id: string;
    /**
     * The appointment type.
     */
    type: AppointmentResponse.type;
    /**
     * The appointment start date/time.
     */
    start: string;
    /**
     * The appointment end date/time.
     */
    end: string;
    /**
     * The current appointment status.
     */
    status: AppointmentResponse.status;
    /**
     * The appointment location (can be a URL, e.g. for a video call).
     */
    location?: string;
    /**
     * A human-readable title for the appointment.
     */
    title: string;
    /**
     * A human-readable description for the appointment.
     */
    description: string;
    /**
     * The appointment participants.
     */
    participants: Array<AppointmentParticipantResponse>;
    additionalData: any;
};

export namespace AppointmentResponse {

    /**
     * The appointment type.
     */
    export enum type {
        PERSONAL = 'PERSONAL',
        ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
        SYNC_VISIT = 'SYNC_VISIT',
        COACHING_1ON1 = 'COACHING_1ON1',
        DIETITIAN_VISIT = 'DIETITIAN_VISIT',
        PHARMACIST_VISIT = 'PHARMACIST_VISIT',
        CCS_MED_CONSULT = 'CCS_MED_CONSULT',
        LAB_RESULTS_REVIEW = 'LAB_RESULTS_REVIEW',
        SPECIALIST_VISIT = 'SPECIALIST_VISIT',
        AT_HOME_LABS_GETLABS = 'AT_HOME_LABS_GETLABS',
    }

    /**
     * The current appointment status.
     */
    export enum status {
        SCHEDULED = 'SCHEDULED',
        CANCELED = 'CANCELED',
        NO_SHOW = 'NO_SHOW',
        COMPLETED = 'COMPLETED',
    }


}


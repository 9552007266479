import * as React from "react";

function IconChevronRightGray() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="chevron-right-gray"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeOpacity=".6"
        strokeWidth="2"
        d="m9 6 5.65 5.65c.2.2.2.5 0 .7L9 18"
      />
    </svg>
  );
}

export default IconChevronRightGray;

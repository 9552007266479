import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import React, { useEffect } from "react";
import sanityQuery from "src/lib/sanityQuery";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { useBloc } from "src/state/state";
import type { CmsCareTeamMember } from "src/types/sanitySchema";
import SanityBlockContent, {
  type SanityContentBlock
} from "src/ui/components/SanityBlockContent/SanityBlockContent";
import Translate from "src/ui/components/Translate/Translate";

const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  margin: 16px -32px 16px; /* Assuming spacing based on theme units */
  padding: 0 32px;
`;

const Member = styled.div`
  min-width: 230px;
  max-width: 230px;
  white-space: normal;
  margin: 0 1rem 0 0;
  text-align: start;
  background: var(--color-cream-dark); /* Assuming a variable is defined */
  padding: 10px;

  figure {
    margin: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const MemberImage = styled(SanityBlockContent)`
  /* Or use  */
  display: block;
  width: 220px;
  height: 220px;
  margin-bottom: 10px;
  border-radius: 0px;
`;

const MemberContent = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  refs: string[];
}

const CareTeamSlider: FunctionComponent<Props> = ({ refs }) => {
  const [{ data }, { fetchAll }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<CmsCareTeamMember>()
  });

  const sortedData = data.sort(
    (a, b) => refs.indexOf(a._id) - refs.indexOf(b._id)
  );

  useEffect(
    () =>
      void fetchAll(
        sanityQuery.getCareTeamMembersByIds(
          refs.map((ref) => JSON.stringify(ref)).join(",")
        )
      ),
    []
  );

  return (
    <Wrapper>
      {sortedData.map((member) => (
        <Member key={member._id}>
          <MemberImage blocks={member.image as unknown as SanityContentBlock} />
          <MemberContent>
            <div style={{ fontSize: 17, marginBottom: 4, marginTop: 14 }}>
              <strong>{member.name}</strong>
            </div>
            <div style={{ fontSize: 15, marginBottom: 6 }}>
              {member.description}
              {member.lwd && (
                <>
                  {member.description && <br />}
                  <Translate msg="livesWithDiabetes" />
                </>
              )}
            </div>
            <div style={{ fontSize: 12, marginTop: "auto" }}>
              <b>{member.title}</b>
            </div>
          </MemberContent>
        </Member>
      ))}
    </Wrapper>
  );
};

export default CareTeamSlider;

import envVariables from "./lib/envVariables";
import { StorageController } from "./state/StorageBloc/StorageBloc";

class MockClient {
  noCacheQueryMatch: RegExp[] = [/featureflags/];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rqRunning: Map<string, Promise<any>> = new Map();

  fetch = async <T>(query: string): Promise<T> => {
    const canCache = this.noCacheQueryMatch.every(
      (match) => !match.test(query)
    );
    if (canCache) {
      const cached = StorageController.getItem(query, sessionStorage);
      if (cached) {
        return JSON.parse(cached) as T;
      }
    }

    if (this.rqRunning.has(query)) {
      return this.rqRunning.get(query) as unknown as Promise<T>;
    }

    const rq = new Promise<T>((resolve, reject) => {
      const url = new URL(`${envVariables.CMS_API_BASE_URL}/api/query`);
      url.searchParams.set("query", query);
      fetch(url)
        .then((res) => res.json())
        .then((json) => {
          resolve(json as T);
          if (canCache) {
            StorageController.setItem(
              query,
              JSON.stringify(json),
              sessionStorage
            );
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            reject(e);
          }
        })
        .finally(() => {
          //this.rqRunning.delete(query);
        });
    });
    this.rqRunning.set(query, rq);
    return rq as unknown as Promise<T>;
  };
}

export default new MockClient();

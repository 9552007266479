import React, { FC } from "react";
import CollectionTitle from "../StyledComponents/CollectionTitle";
import AppointmentCard from "src/ui/components/AppointmentsList/components/AppointmentCard";
import styled from "@emotion/styled";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import { useBloc } from "@blac/react";
import Translate from "src/ui/components/Translate/Translate";
import {
  IconCarouselChevronLeft,
  IconCarouselChevronRight
} from "src/constants/icons";
import { CarouselCubit } from "./components/Carousel/CarouselCubit";
import Carousel from "./components/Carousel/Carousel";
import CarouselItem from "./components/Carousel/CarouselItem";
import Pagination from "./components/Carousel/Pagination";
import { OpacityGradient } from "./components/Carousel/OpacityGradient";
import {
  CarouselButton,
  CarouselButtonWrapper
} from "./components/Carousel/CarouselButton";
import { APP_BREAKPOINT_SM } from "src/constants/layout";

const Wrapper = styled.div`
  margin: 1.5rem 0 4rem;

  @media screen and (max-width: ${APP_BREAKPOINT_SM}px) {
    margin-top: 0.5rem;
  }
`;

const AppointmentsList: FC = () => {
  const [{ scheduledAppointments }] = useBloc(AppointmentsBloc);

  const [{ locked }, { handleScrollTo, itemPrefix }] = useBloc(CarouselCubit);

  const elementsCount = scheduledAppointments.length;
  const isPaginationHidden = elementsCount <= 1;

  if (!elementsCount) {
    return null;
  }

  return (
    <Wrapper className="appointments-list">
      <CollectionTitle>
        <Translate msg="appointment.list.title" />
      </CollectionTitle>

      <Carousel isPaginationHidden={isPaginationHidden}>
        <OpacityGradient elementsCount={elementsCount} />
        {scheduledAppointments.map((appointment, index) => (
          <CarouselItem
            id={`${itemPrefix}-${index}`}
            key={appointment.id}
            // previous items should have higher z-index to fix clipping shadow issue
            zIndex={scheduledAppointments.length - index}
          >
            <AppointmentCard appointment={appointment} />
          </CarouselItem>
        ))}
        <OpacityGradient elementsCount={elementsCount} />
      </Carousel>

      {!isPaginationHidden && (
        <>
          <Pagination elementsCount={elementsCount} />

          <CarouselButtonWrapper>
            <CarouselButton
              disabled={locked}
              onClick={() => handleScrollTo("prev")}
            >
              <IconCarouselChevronLeft />
            </CarouselButton>

            <CarouselButton
              disabled={locked}
              onClick={() => handleScrollTo("next")}
            >
              <IconCarouselChevronRight />
            </CarouselButton>
          </CarouselButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default AppointmentsList;

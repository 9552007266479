import {
  GetMessagesResponseItem,
  MessageControllerService,
  SenderMetadataResponse,
  SuccessGetMessagesResponse
} from "@9amhealth/openapi";
import { App } from "@capacitor/app";
import { Cubit } from "blac";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import type { VirtuosoHandle } from "react-virtuoso";
import appErrors from "src/constants/appErrors";
import { globalEvents } from "src/constants/globalEvents";
import { FileUploadLimit, FileUploadMegabyteLimit } from "src/constants/limits";
import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { DateFormats } from "src/lib/date";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import imageLoadPromise from "src/lib/imageLoadPromise";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import type {
  ObserverCallback,
  WebsocketMessage
} from "src/state/WebSocketBloc/WebSocketBloc";
import { WebsocketMessageType } from "src/state/WebSocketBloc/WebSocketBloc";
import {
  appViewState,
  authenticationState,
  fileState,
  pushNotificationState,
  toast,
  userState,
  websocketState
} from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import { DateFormatter, parseAbsoluteToLocal } from "@internationalized/date";
import { getSupportedUserLocale } from "src/lib/i18next";

export enum MessageMetaField {
  name = "file.<id>.name",
  type = "file.<id>.type",
  size = "file.<id>.size",
  source = "file.<id>.source",
  imageWidth = "file.<id>.image.width",
  imageHeight = "file.<id>.image.height"
}

export interface MessageFileMetadata {
  name: string;
  type: string;
  size: string;
  source: string;
  imageWidth: string;
  imageHeight: string;
}

export type ChatMessageType = "text/html" | "text/plain";
export enum ChatMessageMetaType {
  labReportUpload = "lab-report-upload",
  labValueDisplay = "lab-value-display",
  npsFeedback = "nps-feedback"
}
export type KnownSenderType =
  | "STAFF_CUSTOMER_SUPPORT"
  | "STAFF_DIABETES_EDUCATOR"
  | "STAFF_PHYSICIAN"
  | "STAFF"
  | "USER"
  | undefined;

export interface ChatMessageComputedData {
  localRefId?: string;
  isLocal: boolean;
  sentByUser: boolean;
  showContentValue?: boolean;
  senderName?: string;
  senderDescription?: string;
  messageSentDate?: string;
  isLabValueDisplay?: boolean;
  isLabReportUpload?: boolean;
  isNpsFeedback?: boolean;
  filesIdList?: string[];
  fileAttachments: MessageFileMetadata[];
  attachedLifelineItems: string[];
  contentValueGenerated?: boolean;
}

export interface ChatMessage {
  placeholder?: "bottom" | "top-small" | "top";
  id: string;
  apiItem?: GetMessagesResponseItem;
  computed: ChatMessageComputedData;
  text?: string;
  timestamp: string;
  type?: ChatMessageType;
  senderType?: KnownSenderType;
  metaType?: ChatMessageMetaType;
  contentValueGenerated?: boolean;
}

enum ChatErrorMessages {
  fileUploadFailed = "fileUploadFailed",
  notConnected = "error_websocket_not_connected"
}

export interface ChatError {
  message: ChatErrorMessages;
  text?: string;
  blocking: boolean;
}

export interface ChatViewState {
  messages: ChatMessage[];
  loading: boolean;
  firstItemIndex: number;
  newMessageAvailable?: boolean;
  errors: ChatError[];
  unreadMessages?: number;
}

const KEY_FIRST_ITEM_INDEX = 99999;
const MESSAGES_PER_PAGE = 20;

export class ChatBloc extends Cubit<ChatViewState> {
  verbose = false;
  userId: string = "";
  allMessages: ChatMessage[] = [];
  moreHistoryAvailable = true;
  virtualListElement: VirtuosoHandle | null = null;
  scrollEndState = true;
  keyMessageIndexId: string | null = null;
  chatOpen = false;

  constructor() {
    super({
      messages: [],
      loading: false,
      firstItemIndex: KEY_FIRST_ITEM_INDEX,
      errors: []
    });
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.allMessages = [];
    });
    this.startPolling();
    void this.checkUnreadMessages();

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        messages: [],
        loading: false,
        firstItemIndex: KEY_FIRST_ITEM_INDEX,
        errors: []
      });

      clearTimeout(window.chatStatusTimeout);
      this.polling = false;
    });
  }

  initChat = (options: { userId?: string }): void => {
    const { userId } = options;
    if (!userId) {
      reportErrorSentry(new Error("Init chat without user ID"));
      return;
    }

    this.chatOpen = true;
    this.userId = userId;

    this.registerWebsocketObservers();
    this.addAppStateListeners();

    void this.getCachedMessages();

    appViewState.chatBloc = this;

    void pushNotificationState.removeAllDeliveredNotifications();
  };

  setChatOpen = (setTo: boolean): void => {
    this.chatOpen = setTo;
  };

  log = (message: string, deets?: unknown) => {
    if (featureFlags.getFlag(FeatureFlagName.chatEvents)) {
      // eslint-disable-next-line no-console
      console.warn(`[CHAT] ${message}`, deets ?? undefined);
    }

    logSentryBreadcrumb("chat", message);
  };

  addAppStateListeners = (): void => {
    void App.addListener("appStateChange", (data) => {
      if (data.isActive) {
        void this.checkUnreadMessages();
      }
      if (this.chatOpen && data.isActive) {
        void this.getCachedMessages();
      }
    });
  };

  polling = false;
  startPolling = (): void => {
    if (this.polling) return;
    this.polling = true;
    this.callPolling();
  };

  maxPollCount = 10;
  callPolling = (timeout = 12000) => {
    clearTimeout(window.chatStatusTimeout);
    this.maxPollCount -= 1;
    if (this.maxPollCount < 0) {
      return;
    }
    window.chatStatusTimeout = setTimeout(() => {
      void (async () => {
        clearTimeout(window.chatStatusTimeout);
        const allowPolling = userState.hasAppAccess();
        const isInApp = appViewState.state.inApp;
        const fetch =
          allowPolling && isInApp && authenticationState.accessToken;
        if (fetch) {
          const promises = [this.checkUnreadMessages()];

          const wsConnected =
            websocketState.websocket?.readyState === WebSocket.OPEN;

          if (!wsConnected) {
            this.log("WS is disconnected, polling messages");
            if (this.allMessages.length === 0) {
              promises.push(this.loadFirstPageMessages());
            } else {
              promises.push(this.loadNewerMessages());
            }
          }
          await Promise.all(promises);
        }

        this.callPolling();
      })();
    }, timeout) as unknown as number;
  };

  checkUnreadMessages = async (): Promise<void> => {
    try {
      const result = await MessageControllerService.getMessagingStatus();
      const unreadMessages = result.data.unreadMessageCount;
      if (unreadMessages !== this.state.unreadMessages) {
        this.emit({ ...this.state, unreadMessages });
      }
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  registerWebsocketObservers = (): void => {
    websocketState.addObserver(
      WebsocketMessageType.receivedMessage,
      this.websocketHandleMessage
    );
  };

  registerWebsocketObserversForNotification = (): void => {
    websocketState.addObserver(
      WebsocketMessageType.receivedMessage,
      this.websocketHandleMessageForNotification
    );
  };

  loadingStart = (): void => {
    this.emit({ ...this.state, loading: true });
  };

  loadingEnd = (): void => {
    this.emit({ ...this.state, loading: false });
  };

  websocketHandleMessage: ObserverCallback = (message): void => {
    if (!this.chatOpen || !message) return;
    const { type, payload } = message;

    if (type === WebsocketMessageType.receivedMessage) {
      this.handleMessageSentSuccess(payload as GetMessagesResponseItem);
    }
  };

  websocketHandleMessageForNotification: ObserverCallback = (message): void => {
    if (!message) return;
    const { type } = message;

    if (type === WebsocketMessageType.receivedMessage) {
      void this.checkUnreadMessages();
    }
  };

  handleMessageSentSuccess = (
    payload: GetMessagesResponseItem | undefined
  ): void => {
    if (!payload?.id) return;
    const message = ChatBloc.parseApiMessage(payload);
    this.allMessages.push(message);

    this.setMessagesToState();

    let newMessageNotificationType: "notification" | "scroll" = "notification";
    if (message.senderType === "USER" || this.scrollEndState) {
      newMessageNotificationType = "scroll";
    }

    const smoothScroll = message.senderType !== "USER" || this.scrollEndState;

    switch (newMessageNotificationType) {
      case "scroll":
        this.scrollToMessageId(message.id, smoothScroll);
        break;
      case "notification":
        this.setNewMessageNotification(true);
        break;
    }
  };

  getCachedMessages = async (): Promise<void> => {
    const cached = StorageController.getItem("messages");
    const cachedLocal = StorageController.getItem("messages-local");
    const cachedMessages =
      cached && (JSON.parse(cached) as GetMessagesResponseItem[]);

    const cachedLocalMessages =
      cachedLocal && (JSON.parse(cachedLocal) as GetMessagesResponseItem[]);

    if (cachedLocalMessages && cachedLocalMessages.length > 0) {
      this.localMessages = cachedLocalMessages;
    }

    if (cachedMessages && cachedMessages.length > 0) {
      this.allMessages = cachedMessages
        .map(ChatBloc.parseApiMessage)
        .filter((msg) => !msg.computed.isLocal);

      this.setMessagesToState();
      await this.loadNewerMessages();
    } else {
      await this.loadFirstPageMessages(true);
    }

    await this.processLocalMessages();
  };

  backgroundUpdateMessages = async (): Promise<void> => {
    if (this.isClosed) return;
    try {
      await this.loadFirstPageMessages(false);
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  cacheMessages = (messages: GetMessagesResponseItem[]): void => {
    const sorted = messages.sort((a, b) => {
      const aDate = new Date(a.messageReceiveTimestamp);
      const bDate = new Date(b.messageReceiveTimestamp);
      return aDate.getTime() - bDate.getTime();
    });
    const messagesToCache = sorted.filter((m) => !m.id.startsWith("local_"));
    StorageController.setItem(
      "messages",
      JSON.stringify(messagesToCache.slice(MESSAGES_PER_PAGE * -1))
    );
  };

  loadingMessagesPromise?: Promise<SuccessGetMessagesResponse>;

  loadFirstPageMessages = async (showLoading = true): Promise<void> => {
    if (showLoading) this.loadingStart();

    try {
      const promise =
        this.loadingMessagesPromise ??
        MessageControllerService.listRecentMessages(MESSAGES_PER_PAGE);

      this.loadingMessagesPromise = promise;
      const { data } = await promise;
      this.loadingMessagesPromise = undefined;
      data.messages.reverse();
      this.allMessages.push(...data.messages.map(ChatBloc.parseApiMessage));
      this.setMessagesToState();
    } catch (error) {
      reportErrorSentry(error);
    }

    if (showLoading) this.loadingEnd();
  };

  loadNewerMessages = async () => {
    try {
      const lastMessage = this.allMessages.findLast(
        (msg) => !msg.id.startsWith("local_")
      );
      if (!lastMessage) {
        // load first page messages instead
        throw new Error("No messages to load");
      }

      const promise = MessageControllerService.listNearbyMessages(
        lastMessage.id,
        "newer",
        MESSAGES_PER_PAGE
      );

      const { data } = await promise;
      data.messages.reverse();
      this.allMessages.push(...data.messages.map(ChatBloc.parseApiMessage));
      this.setMessagesToState();
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  withoutLocalMessages = (messages: ChatMessage[]): ChatMessage[] => {
    return messages.filter((m) => !m.computed.isLocal);
  };

  firstMessageIndex = 0;
  lastMessageIndex = 0;
  setMessagesToState = (): void => {
    this.removeSentLocalMessages();
    const allInstanceMessages = this.withoutLocalMessages(this.allMessages);
    const stateMessages = this.withoutLocalMessages(this.state.messages);

    const combined = [...allInstanceMessages, ...stateMessages];

    let messageList = combined.filter(
      (msg, index, self) => self.findIndex((m) => m.id === msg.id) === index
    );

    const toCache = [...messageList];
    // add local messages
    messageList = [...this.localMessagesParsed, ...messageList];

    const messagesInState = {
      local: 0,
      remote: 0
    };

    for (const message of this.state.messages) {
      if (message.computed.isLocal) {
        messagesInState.local++;
      } else {
        messagesInState.remote++;
      }
    }
    if (
      toCache.length === messagesInState.remote &&
      this.localMessagesParsed.length === messagesInState.local
    ) {
      return;
    }

    // merge dedupe with state.messages, sort by createdDate
    messageList.sort((a, b) => {
      const aDate = new Date(a.timestamp);
      const bDate = new Date(b.timestamp);
      return aDate.getTime() - bDate.getTime();
    });

    let { firstItemIndex } = this.state;

    if (!this.keyMessageIndexId) {
      this.keyMessageIndexId = messageList[0]?.id ?? null;
    }

    // always keep the message with the id "this.keyMessageIndexId" at index KEY_FIRST_ITEM_INDEX
    if (this.keyMessageIndexId) {
      const findKeyIndex = messageList.findIndex(
        (m) => m.id === this.keyMessageIndexId
      );
      firstItemIndex = KEY_FIRST_ITEM_INDEX - findKeyIndex;
    }

    this.emit({
      ...this.state,
      messages: messageList,
      firstItemIndex
    });

    this.cacheMessages(toCache.map((m) => m.apiItem).filter(Boolean));

    appViewState.setMessagesState(messageList);
  };

  handleMessageFile = async (
    file: File
  ): Promise<Record<string, string> | undefined> => {
    try {
      let metadata: Record<string, string> = {};
      const fileMeta = await ChatBloc.prepareFileMetaData(file);
      const upload = await fileState.uploadFile({ file });
      if (!upload) return;

      const fileKey = fileMeta.files;
      metadata = {
        ...fileMeta,
        [ChatBloc.getFileMetaKey(fileKey, MessageMetaField.source)]: upload.id
      };

      return metadata;
    } catch (error) {
      reportErrorSentry(error);
      const errorMessage = (error as Error | undefined)?.message;
      if (errorMessage === appErrors.upload_max_filesize_exceeded) {
        toast.error("error_file_too_large", {
          max: `${FileUploadMegabyteLimit}MB`,
          file: file.name
        });
      }
    }
  };

  postMessage = async (
    message: string,
    files: FileList | null,
    meta?: Record<string, string | string[] | boolean>
  ): Promise<void> => {
    let metadata: Record<string, string | string[] | boolean> = {};

    const filesArray = Array.from(files ?? []);

    try {
      const filesUploaded = await Promise.all([
        ...filesArray.map(async (file) => this.handleMessageFile(file))
      ]);
      const successFiles = filesUploaded.filter(Boolean);
      const allFilesFailed =
        Boolean(filesUploaded.length) && filesUploaded.every((file) => !file);

      // stop if all files failed
      if (allFilesFailed) return;

      const filesMetaKey = [];
      for (const fileMeta of successFiles) {
        filesMetaKey.push(fileMeta.files);
        metadata = {
          ...metadata,
          ...fileMeta,
          ...(meta ?? {})
        };
      }
      metadata.files = filesMetaKey.join(",");
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return;
    }

    await this.sendMessageWithFallback({
      content: message,
      meta: metadata
    });
  };

  sendMessageWithFallback = async (msg: {
    content: string;
    meta?: Record<string, string | string[] | boolean>;
  }): Promise<void> => {
    const { content, meta = {} } = msg;

    const localId = `local_${nanoid(5)}`;
    meta["message:local-id"] = localId;

    const timeStamp = dayjs().format(DateFormats.ISO_FULL);
    meta["message:send-timestamp"] = timeStamp;

    const asMessageResponseItem = {
      id: localId,
      senderId: this.userId,
      messageOwnerId: this.userId,
      senderMetadata: {
        role: SenderMetadataResponse.role.USER
      },
      messageReceiveTimestamp: timeStamp,
      contentType: "text/html",
      contentValue: content ? this.parseTextToHtml(content) : "",
      metadata: meta,
      senderType: GetMessagesResponseItem.senderType.USER
    } satisfies GetMessagesResponseItem;

    const wsMessage = {
      type: WebsocketMessageType.sendMessage,
      payload: {
        contentType: asMessageResponseItem.contentType,
        contentValue: asMessageResponseItem.contentValue,
        metadata: asMessageResponseItem.metadata
      }
    } satisfies WebsocketMessage;

    try {
      this.addLocalMessage(asMessageResponseItem);
      await websocketState.send(wsMessage);
    } catch (e) {
      websocketState.removeLocalMessage(wsMessage);
      await this.sendMessageFallbackRequest(wsMessage.payload);
      reportErrorSentry(new Error("Sending Message WS Error", { cause: e }));
    }
  };

  localMessages: GetMessagesResponseItem[] = [];

  sendMessageFallbackRequest = async (msg: {
    contentType: string;
    contentValue?: string;
    metadata?: AnyObject;
  }) => {
    this.log("Sending message with POST");
    const user = this.userId;
    if (!user) {
      reportErrorSentry(new Error("Sending Message No User"));
      return;
    }
    try {
      const res = await MessageControllerService.postToOwnersLifeline(user, {
        metadata: msg.metadata,
        contentType: msg.contentType,
        contentValue: msg.contentValue
      });
      this.handleMessageSentSuccess(res.data.storedMessage);
    } catch (e) {
      toast.error("error_websocket_not_connected");
      reportErrorSentry(new Error("Sending Message POST Error", { cause: e }));
    }
  };

  addLocalMessage = (message: GetMessagesResponseItem): void => {
    let newLocalMessages = [...this.localMessages, message];
    // remove duplicate messages, unique by id
    newLocalMessages = newLocalMessages.filter(
      (m, i, self) => i === self.findIndex((m2) => m2.id === m.id)
    );

    this.localMessages = newLocalMessages;

    // sync to localStorage
    StorageController.setItem(
      "messages-local",
      JSON.stringify(this.localMessages)
    );

    this.setMessagesToState();
  };

  get localMessagesParsed(): ChatMessage[] {
    return this.localMessages.map(ChatBloc.parseApiMessage);
  }

  processLocalMessages = async (): Promise<void> => {
    this.removeSentLocalMessages();

    const { localMessages } = this;
    if (localMessages.length === 0) return;

    for (const message of localMessages) {
      const payload = {
        contentType: message.contentType,
        contentValue: message.contentValue,
        metadata: message.metadata
      };

      const wsMessage = {
        type: WebsocketMessageType.sendMessage,
        payload
      };
      try {
        if (websocketState.websocket?.readyState === WebSocket.OPEN) {
          await websocketState.send(wsMessage);
        } else {
          this.log("WS is disconnected, posting message");
          await this.sendMessageFallbackRequest(payload);
        }
      } catch (e) {
        reportErrorSentry(new Error("Sending Message WS Error", { cause: e }));
      }
    }

    this.removeSentLocalMessages();
  };

  removeSentLocalMessages = (): void => {
    const { localMessages, allMessages: remoteMessages } = this;
    const filtered = [...localMessages];

    for (const localMsg of localMessages) {
      const localMeta = localMsg.metadata ?? {};
      const localId = localMeta["message:local-id"];

      const inRemote = remoteMessages.findIndex((m) => {
        const match = m.computed.localRefId === localId;
        return match;
      });
      if (inRemote !== -1) {
        filtered.splice(filtered.indexOf(localMsg), 1);
      }
    }

    this.localMessages = filtered;

    // sync to localStorage
    StorageController.setItem(
      "messages-local",
      JSON.stringify(this.localMessages)
    );
  };

  public readonly parseTextToHtml = (text: string): string => {
    const content = text.trim().replace(/\n/g, "<br>").replace(/\s/g, "&nbsp;");
    return `<div>${content}</div>`;
  };

  /**
   * Load the next page of messages
   */
  public readonly loadPreviousMessages = async (): Promise<void> => {
    if (!this.moreHistoryAvailable || this.state.loading) {
      return;
    }
    const [firstMessage] = this.state.messages;

    let newMessages: ChatMessage[] = [];

    try {
      this.emit({ ...this.state, loading: true });
      const response = await MessageControllerService.listNearbyMessages(
        firstMessage.id,
        "older",
        MESSAGES_PER_PAGE
      );
      const { messages } = response.data;

      if (messages.length === 0 || messages.length < MESSAGES_PER_PAGE) {
        this.moreHistoryAvailable = false;
      }

      newMessages = messages.map(ChatBloc.parseApiMessage);
    } catch (e) {
      reportErrorSentry(e);
    }

    this.emit({ ...this.state, loading: false });

    if (newMessages.length > 0) {
      this.allMessages.push(...newMessages);
      this.setMessagesToState();
    }
  };

  public readonly setNewMessageNotification = (set: boolean): void => {
    this.emit({
      ...this.state,
      newMessageAvailable: set
    });
  };

  public readonly registerVirtualList = (
    element: VirtuosoHandle | null
  ): void => {
    // eslint-disable-next-line no-console
    if (this.verbose) console.log("registerVirtualList", element);
    this.virtualListElement = element;
  };

  scrollToMessageId = (id: string, smooth = true): void => {
    // add timeout to allow state to be updated before we scroll
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (!this.virtualListElement) {
          // eslint-disable-next-line no-console
          console.warn("virtualListElement not found");
          return;
        }

        const index = this.state.messages.findIndex((m) => m.id === id);
        if (index === -1) {
          reportErrorSentry(new Error("Message not found"));
          return;
        }

        this.virtualListElement.scrollToIndex({
          index,
          behavior: smooth ? "smooth" : "auto",
          align: "start"
        });
      });
    }, 30);
  };

  scrollToBottom = (smooth: boolean): void => {
    // add timeout to allow state to be updated before we scroll
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (!this.virtualListElement) {
          // eslint-disable-next-line no-console
          console.warn("virtualListElement not found");
          return;
        }

        this.scrollEndState = true;
        this.virtualListElement.scrollToIndex({
          index: this.state.messages.length + 100,
          behavior: smooth ? "smooth" : "auto",
          align: "end"
        });
      });
    }, 30);
  };

  setScrollEndState = (setTo: boolean): void => {
    this.scrollEndState = setTo;
    this.setNewMessageNotification(false);
  };

  addError = (
    message: ChatErrorMessages,
    blocking = false,
    text?: string
  ): void => {
    reportErrorSentry(new Error(message));

    this.emit({
      ...this.state,
      errors: [
        ...this.state.errors,
        {
          message,
          text,
          blocking
        }
      ]
    });

    // remove the error after 5 seconds if it's not blocking
    if (!blocking) {
      setTimeout(() => {
        this.emit({
          ...this.state,
          errors: this.state.errors.filter((e) => e.message !== message)
        });
      }, 5000);
    }
  };

  /*
  STATIC METHODS
  */

  public static readonly parseApiMessage = (
    apiMessage: GetMessagesResponseItem
  ): ChatMessage => {
    let text = apiMessage.contentValue ?? "";
    if (text === "<div></div>") {
      text = "";
    }

    const meta =
      apiMessage.metadata ??
      ({} as Record<string, boolean | number | string | undefined>);
    const smd =
      apiMessage.senderMetadata ??
      ({} as Record<string, boolean | number | string | undefined>);
    const metaType = meta["message:type"] as ChatMessageMetaType | undefined;
    const contentValueGenerated = meta["content-value:generated"] as
      | boolean
      | undefined;
    const filesIdList = meta.filesIdList as string[] | undefined;

    const dateLocal = parseAbsoluteToLocal(apiMessage.messageReceiveTimestamp);

    const isLocal = apiMessage.id.startsWith("local_");

    const localRefId: string | undefined = meta["message:local-id"] as
      | string
      | undefined;

    const dateTimeFormat = new DateFormatter(getSupportedUserLocale(), {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hourCycle: "h12"
    });

    const computed: ChatMessageComputedData = {
      localRefId,
      isLocal,
      sentByUser:
        smd.role === "USER" &&
        apiMessage.senderId === apiMessage.messageOwnerId,
      fileAttachments: ChatBloc.getMessageFileAttachments(apiMessage),
      attachedLifelineItems:
        ChatBloc.getMessageAttachedLifelineItems(apiMessage),
      senderDescription: ChatBloc.getMessageSenderDescription(apiMessage),
      senderName: `${smd.displayName ?? ""}`.trim(),
      messageSentDate: dateTimeFormat.format(dateLocal.toDate()),
      isLabValueDisplay: metaType === ChatMessageMetaType.labValueDisplay,
      isLabReportUpload: metaType === ChatMessageMetaType.labReportUpload,
      isNpsFeedback: metaType === ChatMessageMetaType.npsFeedback,
      contentValueGenerated,
      filesIdList
    };

    computed.showContentValue = !contentValueGenerated;

    const msg: ChatMessage = {
      id: apiMessage.id,
      apiItem: apiMessage,
      computed,
      text,
      type: apiMessage.contentType as ChatMessageType,
      timestamp: apiMessage.messageReceiveTimestamp,
      senderType: smd.role as KnownSenderType,
      metaType,
      contentValueGenerated
    };
    return msg;
  };

  static readonly prepareFileMetaData = async (
    file: File
  ): Promise<Record<string, string>> => {
    const { getFileMetaKey } = ChatBloc;
    if (file.size > FileUploadLimit) {
      throw new Error(appErrors.upload_max_filesize_exceeded);
    }

    const metadata: {
      [key: string]: string;
      files: string;
    } = {
      files: ""
    };

    const fileId = nanoid(5);
    metadata.files = fileId;
    metadata[getFileMetaKey(fileId, MessageMetaField.name)] = file.name;
    metadata[getFileMetaKey(fileId, MessageMetaField.type)] = file.type;
    metadata[getFileMetaKey(fileId, MessageMetaField.size)] =
      file.size.toString();

    if (file.type.startsWith("image")) {
      const filePath = URL.createObjectURL(file);
      const img = await imageLoadPromise(filePath);
      metadata[getFileMetaKey(fileId, MessageMetaField.source)] = filePath;
      metadata[getFileMetaKey(fileId, MessageMetaField.imageHeight)] =
        img.height.toString();
      metadata[getFileMetaKey(fileId, MessageMetaField.imageWidth)] =
        img.width.toString();
    }

    return metadata;
  };

  public static readonly getMessageAttachedLifelineItems = (
    message: GetMessagesResponseItem
  ): string[] => {
    const meta = message.metadata ?? {};
    return (
      (meta["message:lifeline-item-ids"] as unknown as string[] | undefined) ??
      []
    );
  };

  public getMessagesThatContainMetadataAttribute = (
    metadataKey: string,
    metadataValue: unknown
  ): ChatMessage[] => {
    return this.state.messages.filter(
      (message) => message.apiItem?.metadata?.[metadataKey] === metadataValue
    );
  };

  public static readonly getMessageSenderDescription = (
    message: GetMessagesResponseItem
  ): string => {
    const smd: Record<string, string | undefined> =
      message.senderMetadata ?? {};
    const jobTitleTranslation = translate(
      `job_title_${smd.role}` as TranslationKey,
      {},
      "" as TranslationKey
    );
    const jobTitle = smd.displayRole ?? jobTitleTranslation;
    return jobTitle;
  };

  public static readonly getMessageFileAttachments = (
    message: GetMessagesResponseItem
  ): MessageFileMetadata[] => {
    const meta = message.metadata ?? {};
    const fileString = `${meta.files ?? ""}`;
    return fileString
      .split(",")
      .filter(Boolean)
      .map((id) => ChatBloc.getAllFileMetaValues(meta, id));
  };

  public static readonly getMetaValue = (
    metadata: Record<string, string>,
    fileId: string,
    field: MessageMetaField
  ): string => {
    const key = ChatBloc.getFileMetaKey(fileId, field);
    return metadata[key] || "";
  };

  public static readonly getAllFileMetaValues = (
    metadata: Record<string, string>,
    fileId: string
  ): MessageFileMetadata => {
    const getKey = ChatBloc.getMetaValue;
    const { name, size, type, source, imageWidth, imageHeight } =
      MessageMetaField;
    const values: MessageFileMetadata = {
      name: getKey(metadata, fileId, name),
      size: getKey(metadata, fileId, size),
      type: getKey(metadata, fileId, type),
      source: getKey(metadata, fileId, source),
      imageWidth: getKey(metadata, fileId, imageWidth),
      imageHeight: getKey(metadata, fileId, imageHeight)
    };
    return values;
  };

  public static readonly getFileMetaKey = (
    fileId: string,
    field: MessageMetaField
  ): string => {
    return field.replace("<id>", fileId);
  };

  lastReadMessageId: string | null = null;
  markMessageAsRead = (message: ChatMessage): void => {
    if (this.lastReadMessageId === message.id) return;
    MessageControllerService.markMessageAsRead(message.id)
      .then(() => {
        this.lastReadMessageId = message.id;
        this.emit({
          ...this.state,
          unreadMessages: 0
        });
      })
      .catch(() => {
        // handled by the API controller
      });
  };
}

import styled from "@emotion/styled";
import { IonContent } from "@ionic/react";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import { BLOOD_SPOT_SKU } from "src/constants/misc";
import Path from "src/constants/path";
import PushNotificationsBloc from "src/hybrid/components/PushNotificationsControls";
import { createAppPath } from "src/lib/createAppPath";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import AnimatedPage from "src/ui/components/AnimatedPage/AnimatedPage";
import AppPageProvider from "src/ui/components/AppPageProvider/AppPageProvider";
import AppStatusBar from "src/ui/components/AppStatusBar/AppStatusBar";
import Chat from "src/ui/components/Chat/Chat";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import LabResultsDataSubPageRouter from "src/ui/components/LabResultsDataSubPageRouter/LabResultsDataSubPageRouter";
import { PlanOverview } from "src/ui/components/PlanOverview/PlanOverview";
import ProfileOverview from "src/ui/components/ProfileOverview/ProfileOverview";
import ProfileRouter from "src/ui/components/ProfileOverview/ProfileRouter";
import ProgramDetails from "src/ui/components/ProgramDetails/ProgramDetails";
import ProgramOverview from "src/ui/components/ProgramOverview/ProgramOverview";
import ReadMore from "src/ui/components/ReadMore/ReadMore";
import AppTopSpacer from "src/ui/components/StyledComponents/AppTopSpacer";
import { Prompts } from "src/ui/pages/AppPage/components/Prompts/Prompts";
import HomePage from "src/ui/pages/HomePage/HomePage";
import AppQueryPopupsRedirects from "../AppQueryPopups/AppQueryPopupsRedirects";
import InAppQuestionnaire from "../InAppQuestionnaire/InAppQuestionnaire";
import PlanRouter from "../PlanRouter/PlanRouter";
import { ChatBloc } from "../Chat/ChatBloc";

const Async = {
  CustomerIo: React.lazy(
    async () => import("src/ui/components/CustomerIo/CustomerIo")
  )
};

const IonContentStyled = styled(IonContent)`
  background-color: var(--color-cream);
`;

const AppPadding = styled.div`
  label: AppPadding;
  padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 56px + 2rem);
  max-width: ${APP_CONTENT_WIDTH}px;
  margin: 0 auto;

  @media screen and (min-width: 730px) {
    padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 4rem);
    padding-top: calc(var(--ion-safe-area-top, 0px) + 64px);
  }

  &:has(.disable-app-padding) {
    padding-bottom: 0;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const DisableAppPadding = () => (
  <div className="disable-app-padding" style={{ display: "none" }} />
);

const AppRouter: FC = () => {
  const [userPreferences, { loadUserPreferences }] =
    useBloc(UserPreferencesCubit);

  const navigate = useNavigate();
  const location = useLocation();

  const [, { setNavigateMethod }] = useBloc(PushNotificationsBloc, {
    subscribe: false
  });
  const [, { registerWebsocketObserversForNotification, checkUnreadMessages }] =
    useBloc(ChatBloc);

  setNavigateMethod(navigate);

  const onboardingDone = useMemo(
    () => userPreferences[UserPreferenceKeys.onboardingDone],
    [userPreferences]
  );

  useEffect(() => {
    void checkUnreadMessages();
    registerWebsocketObserversForNotification();
    void loadUserPreferences();
    // remove zendesk widget
    SignupCubit.removeSupportChat();
  }, []);

  useEffect(() => {
    const ionContent = document.querySelector("ion-content");
    const scrollable = ionContent?.shadowRoot?.querySelector(".inner-scroll");
    scrollable?.scrollTo({ top: 0, behavior: "instant" });
  }, [location.pathname]);

  // clear onboarding data and redirect to '/app/messenger' from '/app'
  useEffect(() => {
    const target = createAppPath(Path.home);

    if (
      (location.pathname as Path) === Path.root ||
      location.pathname === `/${Path.app}`
    ) {
      navigate(target);
    }
  }, [onboardingDone]);

  return (
    <>
      <AppQueryPopupsRedirects />
      <AppPageProvider>
        <CustomSuspense>
          <Async.CustomerIo />
        </CustomSuspense>
        <IonContentStyled id={"ion-c"} scrollEvents>
          <Prompts />
          <AppPadding>
            <Routes>
              {/* redirect old "/messages" to new "/chat" */}
              <Route
                path={Path.messages}
                element={<Navigate to={createAppPath(Path.chat)} />}
              />

              <Route
                path={`${Path.chat}/:subpage?/:id?`}
                element={
                  <AnimatedPage data-testid="messages-page">
                    <Chat />
                  </AnimatedPage>
                }
              />

              <Route path={Path.home} element={<HomePage />} />

              <Route
                path={`${Path.signupQuestionnaire}/:id`}
                element={<InAppQuestionnaire />}
              />

              <Route
                path={Path.myPlan}
                element={
                  <AnimatedPage data-testid="plan-overview-page">
                    <ProgramOverview />
                    <PlanOverview />
                  </AnimatedPage>
                }
              />

              <Route
                path={`${Path.myPlan}/*`}
                element={
                  <AnimatedPage data-testid="plan-sub-page">
                    <PlanRouter />
                  </AnimatedPage>
                }
              />

              <Route
                path={Path.program}
                element={
                  <AnimatedPage data-testid="program-page">
                    <AppTopSpacer />
                    <ProgramDetails />
                  </AnimatedPage>
                }
              />

              <Route
                path={`${Path.profile}/*`}
                element={
                  <AnimatedPage data-testid="profile-router-page">
                    <ProfileRouter />
                  </AnimatedPage>
                }
              />

              <Route
                path={Path.profile}
                element={
                  <AnimatedPage data-testid="profile-page">
                    <AppTopSpacer />
                    <ProfileOverview />
                  </AnimatedPage>
                }
              />

              <Route
                path={`${Path.labResults}/*`}
                element={
                  <AnimatedPage data-testid="lab-results-page">
                    <LabResultsDataSubPageRouter />
                  </AnimatedPage>
                }
              />

              <Route path="*" element={<Navigate to={Path.home} />} />
            </Routes>
          </AppPadding>
        </IonContentStyled>
      </AppPageProvider>
      <AppStatusBar />
      <ReadMore
        hidden
        name="labinstructions"
        slug={BLOOD_SPOT_SKU}
        fetchFromCms={false}
      />
    </>
  );
};

export default AppRouter;

import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import Path from "src/constants/path";
import { getSupportedUserLanguage } from "src/lib/i18next";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AppointmentsList from "src/ui/components/AppointmentsList/AppointmentsList";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import Branding from "src/ui/components/Branding/Branding";
import DailyReadingsWidget from "src/ui/components/DailyReadings/DailyReadingsWidget";
import Loader from "src/ui/components/Loader/Loader";
import OnboardingSteps from "src/ui/components/OnboardingSteps/OnboardingSteps";
import PoweredByWidget from "src/ui/components/PoweredByWidget/PoweredByWidget";
import RxDietCard from "src/ui/components/RxDiet/RxDietCard";
import AppTopSpacer from "src/ui/components/StyledComponents/AppTopSpacer";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import TaskList from "src/ui/components/TaskList/TaskList";
import Translate from "src/ui/components/Translate/Translate";
import UserEducationalFeed from "src/ui/components/UserEducationalFeed/UserEducationalFeed";
import UserEducationalFeedOld from "src/ui/components/UserEducationalFeed/UserEducationalFeedOld";

const Wrap = styled.div`
  label: HomePageWrap;
  max-width: ${APP_CONTENT_WIDTH}px;
  margin: 0 1.4rem;
  height: 100%;

  & > * {
    position: relative;
    z-index: 2;
  }

  .appointments-list {
    z-index: 1;
  }
`;

const HomePage = () => {
  const [
    { showOnboardingScreen, showHomeAnnouncementCard },
    { checkForOnboardingCompleted }
  ] = useBloc(AppViewCubit);

  const language = getSupportedUserLanguage();

  useEffect(() => {
    void checkForOnboardingCompleted();
  }, []);

  useEffect(() => {
    if (showHomeAnnouncementCard) {
      AppQueryPopupsController.openPopup(AppPopup.announcementCard, {
        additionalParameters: { screen: Path.home }
      });
    }
  }, [showHomeAnnouncementCard]);

  return (
    <>
      {/* TODO: Decide when to unlock full home screen */}

      {showOnboardingScreen === false && (
        <Wrap>
          <AppTopSpacer pt />
          <DailyReadingsWidget />
          <PoweredByWidget />

          <Branding
            style={{
              marginBlockEnd: "1.6em"
            }}
          />

          <AppointmentsList />

          <TaskList
            programs={[
              KnownProgram.ONBOARDING,
              KnownProgram.CARE,
              KnownProgram.MEAL_PLAN,
              KnownProgram.LIFEBALANCE,
              KnownProgram.BEHAVIORAL_HEALTH_SCREENING
            ]}
          />

          <RxDietCard />

          <nine-spacer s="md" />
          <CollectionTitle>
            <Translate msg="articles.title" />
          </CollectionTitle>
          {language === "es" ? (
            <UserEducationalFeedOld />
          ) : (
            <UserEducationalFeed />
          )}
        </Wrap>
      )}

      {showOnboardingScreen === null && <Loader fixed active />}
      {showOnboardingScreen === true && <OnboardingSteps />}
    </>
  );
};

export default HomePage;

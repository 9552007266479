import type { BlockContentProps } from "@sanity/block-content-to-react";
import BlockContent from "@sanity/block-content-to-react";
import type { FunctionComponent, ReactElement } from "react";
import React, { useEffect, useMemo } from "react";
import envVariables from "src/lib/envVariables";
import sanityQuery from "src/lib/sanityQuery";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { useBloc } from "src/state/state";
import type { Box, SimpleCmsImageData } from "src/types/sanitySchema";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";

const Async = {
  CareTeamSlider: React.lazy(
    async () => import("src/ui/components/CareTeamSlider/CareTeamSlider")
  ),
  InfoBox: React.lazy(async () => import("src/ui/components/InfoBox/InfoBox")),
  ImageList: React.lazy(
    async () =>
      import("src/ui/components/SanityBlockContent/components/ImageList")
  )
};

interface SerializerNode<T> {
  node: T;
}

interface CareTeamSliderObject {
  members: { _ref: string }[];
}

interface InfoBoxObject {
  boxDecorations: boolean;
  boxes: Box[];
}

interface ImageListObject {
  images: SimpleCmsImageData[];
}

const serializers = ({
  imageClassName
}: {
  imageClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Record<string, any> => ({
  types: {
    careTeamSlider: ({
      node
    }: SerializerNode<CareTeamSliderObject>): ReactElement => {
      const refs = node.members.map((member) => member._ref);

      return <Async.CareTeamSlider refs={refs} />;
    },
    infoBoxes: ({ node }: SerializerNode<InfoBoxObject>): ReactElement => (
      <Async.InfoBox boxes={node.boxes} />
    ),
    reviews: (): ReactElement => {
      return <span></span>;
    },
    imageList: ({ node }: SerializerNode<ImageListObject>): ReactElement => (
      <Async.ImageList images={node.images} />
    ),
    image: ({ node }: SerializerNode<SimpleCmsImageData>): ReactElement => {
      interface Metadata {
        _type: string;
        blurHash: string;
        dimensions: Record<string, number>;
        hasAlpha: boolean;
        isOpaque: boolean;
        lqip: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        palette: any;
      }

      interface ImageRootObject {
        _createdAt: Date;
        _id: string;
        _rev: string;
        _type: string;
        _updatedAt: Date;
        assetId: string;
        extension: string;
        metadata: Metadata;
        mimeType: string;
        originalFilename: string;
        path: string;
        sha1hash: string;
        size: number;
        uploadId: string;
        url: string;
      }

      const [, { fetchFirst, firstElement: sanityImage }] = useBloc(
        SanityCubit,
        { create: () => new SanityCubit<ImageRootObject>() }
      );

      useEffect(() => {
        if (!node.asset._ref) return;
        void fetchFirst(sanityQuery.getById(node.asset._ref));
      }, [node.asset._ref]);

      if (!sanityImage) return <></>;
      return <ResponsiveImage image={sanityImage} className={imageClassName} />;
    }
  }
});

export type SanityContentBlock = {
  _type: string;
};

interface SanityBlockContentProps extends BlockContentProps {
  imageClassName?: string;
  blocks: SanityContentBlock | SanityContentBlock[] | undefined;
}

const SanityBlockContent: FunctionComponent<SanityBlockContentProps> = ({
  imageClassName,
  ...props
}): React.ReactElement => {
  const memoizedSerializers = useMemo(
    () => serializers({ imageClassName }),
    [imageClassName]
  );

  return (
    <BlockContent
      {...props}
      blocks={props.blocks ?? []}
      dataset={envVariables.SANITY_PROJECT_ID}
      projectId={envVariables.SANITY_STUDIO_API_DATASET}
      serializers={memoizedSerializers}
    />
  );
};

export default SanityBlockContent;

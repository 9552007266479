/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScheduleAppointmentRequest } from '../models/ScheduleAppointmentRequest';
import type { SuccessAppointmentResponse } from '../models/SuccessAppointmentResponse';
import type { SuccessCollectionAvailableSlotResponse } from '../models/SuccessCollectionAvailableSlotResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentSchedulingControllerService {

    /**
     * Schedules an appointment with the provided type.
     * @param appointmentType
     * @param requestBody
     * @returns SuccessAppointmentResponse OK
     * @throws ApiError
     */
    public static scheduleAppointment(
        appointmentType: 'PERSONAL' | 'ONBOARDING_SPECIALIST' | 'SYNC_VISIT' | 'COACHING_1ON1' | 'DIETITIAN_VISIT' | 'PHARMACIST_VISIT' | 'CCS_MED_CONSULT' | 'LAB_RESULTS_REVIEW' | 'SPECIALIST_VISIT' | 'AT_HOME_LABS_GETLABS',
        requestBody: ScheduleAppointmentRequest,
    ): CancelablePromise<SuccessAppointmentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/appointments/scheduling/{appointmentType}/schedule',
            path: {
                'appointmentType': appointmentType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns available slots for booking an appointment with the provided type.
     * @param appointmentType
     * @param from
     * @param to
     * @param participantUserId
     * @returns SuccessCollectionAvailableSlotResponse OK
     * @throws ApiError
     */
    public static getAvailableSlots(
        appointmentType: 'PERSONAL' | 'ONBOARDING_SPECIALIST' | 'SYNC_VISIT' | 'COACHING_1ON1' | 'DIETITIAN_VISIT' | 'PHARMACIST_VISIT' | 'CCS_MED_CONSULT' | 'LAB_RESULTS_REVIEW' | 'SPECIALIST_VISIT' | 'AT_HOME_LABS_GETLABS',
        from: string,
        to: string,
        participantUserId?: Array<string>,
    ): CancelablePromise<SuccessCollectionAvailableSlotResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/scheduling/{appointmentType}/available',
            path: {
                'appointmentType': appointmentType,
            },
            query: {
                'from': from,
                'to': to,
                'participantUserId': participantUserId,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}

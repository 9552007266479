import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import Link from "src/ui/components/Link/Link";
import NavBadge from "src/ui/components/NavBadge/NavBadge";

const NavItem = styled.li`
  position: relative;
  flex: 1;
  padding: 0.5rem 0 0.4rem;
  display: block;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  span {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

const IconStack = styled.div`
  position: relative;
  --size: 1.65rem;
  width: var(--size);
  height: var(--size);
  margin-bottom: 0.2rem;
`;

const Icon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &:last-of-type {
    opacity: 0;
  }

  [aria-current="page"] & {
    opacity: 0;
    &:last-of-type {
      opacity: 1;
    }
  }
`;

const MobileNavItem: FC<{
  label: string;
  value: string;
  icon?: {
    active: { src: string; alt: string };
    normal: { src: string; alt: string };
  };
}> = ({ label, icon, value }) => {
  const location = useLocation();
  const to = `/app/${value}`;

  const isActivePath = location.pathname.startsWith(to);

  return (
    <NavItem>
      <Link to={to} aria-current={isActivePath ? "page" : undefined}>
        <IconStack aria-hidden>
          <Icon src={icon?.normal.src} alt={icon?.normal.alt} />
          <Icon src={icon?.active.src} alt={icon?.active.alt} />
        </IconStack>
        <NavBadge path={value} />

        <span>{label}</span>
      </Link>
    </NavItem>
  );
};

export default MobileNavItem;

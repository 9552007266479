/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data for updating an appointment.
 */
export type AppointmentUpdateRequest = {
    /**
     * The status of the appointment.
     */
    status: AppointmentUpdateRequest.status;
    /**
     * The start date and time for the appointment.
     */
    start: string;
    /**
     * The end date and time for the appointment.
     */
    end: string;
    /**
     * A list of users for which the slot is available.
     */
    participantUserIds: Array<string>;
};

export namespace AppointmentUpdateRequest {

    /**
     * The status of the appointment.
     */
    export enum status {
        SCHEDULED = 'SCHEDULED',
        CANCELED = 'CANCELED',
        NO_SHOW = 'NO_SHOW',
        COMPLETED = 'COMPLETED',
    }


}


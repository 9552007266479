import { ObservationControllerService } from "@9amhealth/openapi";
import {
  DateDuration,
  DateTimeDuration,
  DateValue,
  endOfMonth,
  endOfWeek,
  getLocalTimeZone,
  now,
  parseDateTime,
  startOfMonth,
  startOfWeek,
  toCalendarDate,
  today
} from "@internationalized/date";
import { Cubit } from "blac-next";
import { Observation } from "fhir/r4";
import React, { ReactNode } from "react";
import { LoincCodingCode } from "src/constants/fhir";
import { parseDateMultiTry } from "src/lib/date";
import { getSupportedUserLanguage } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { cmToInch, kgToPounds } from "src/lib/unitConversion";
import {
  memStorage,
  StorageController
} from "src/state/StorageBloc/StorageBloc";

type DataValueCalculation = "average" | "single";

export type ChartDataItem = {
  value: number;
  unit: string;
  time: number;
  date: DateValue;
  valueCalculation: DataValueCalculation;
  label?: string;
  valueString?: string;
};

export type ChartDateItemPrimitive = Omit<ChartDataItem, "date"> & {
  date: string;
};

type DateRange = {
  fromDate: DateValue;
  toDate: DateValue;
};

export type Progress = {
  difference?: number;
  unit?: string;
};

type ChartTimeRange = {
  domain: DateTimeDuration;
  /** Label for the time range */
  label: DateTimeDuration;
  /** Average values in this interval, default is undefined which will not average out values */
  average?: DateTimeDuration;
};

interface DataHubState<T> {
  activeLoincCode?: LoincCodingCode;
  activeTimeRange?: ChartTimeRange;
  availableTimeRanges?: ChartTimeRange[];
  activeProgress?: Progress;
  /** Chart data, undefined means that its loading */
  chartData?: T[];
  chartLabel?: {
    domain: number[];
    ticks: number[];
  };
}

export const DataHubAvailableLoincCodes: LoincCodingCode[] = [
  LoincCodingCode.weight,
  LoincCodingCode.waistCircumference,
  LoincCodingCode.stepsInDay,
  LoincCodingCode.hba1c,
  // the rest are not yet implemented
  LoincCodingCode.bloodPressure,
  LoincCodingCode.bloodGlucoseCapillary
];

export const LoincCodeGroups: Partial<
  Record<LoincCodingCode, LoincCodingCode[]>
> = {
  [LoincCodingCode.bloodGlucoseCapillary]: [
    LoincCodingCode.bloodGlucoseCapillary,
    LoincCodingCode.bloodGlucoseCapillaryFasting
  ]
};

const LoincCodeToTimeRangesRecord: Partial<
  Record<LoincCodingCode, ChartTimeRange[]>
> = {
  [LoincCodingCode.hba1c]: [
    { domain: { months: 3 }, average: { weeks: 1 }, label: { weeks: 1 } },
    { domain: { months: 6 }, average: { weeks: 1 }, label: { months: 1 } },
    { domain: { years: 1 }, average: { months: 1 }, label: { months: 1 } }
  ],
  [LoincCodingCode.weight]: [
    { domain: { weeks: 1 }, label: { days: 1 }, average: { hours: 1 } },
    { domain: { months: 3 }, average: { weeks: 1 }, label: { weeks: 1 } },
    { domain: { months: 6 }, average: { weeks: 1 }, label: { months: 1 } },
    { domain: { years: 1 }, average: { months: 1 }, label: { months: 1 } }
  ],
  [LoincCodingCode.waistCircumference]: [
    { domain: { weeks: 1 }, label: { days: 1 } },
    { domain: { months: 3 }, average: { weeks: 1 }, label: { weeks: 1 } },
    { domain: { months: 6 }, average: { weeks: 1 }, label: { months: 1 } },
    { domain: { years: 1 }, average: { months: 1 }, label: { months: 1 } }
  ],
  [LoincCodingCode.stepsInDay]: [
    { domain: { weeks: 1 }, average: { days: 1 }, label: { days: 1 } },
    { domain: { months: 3 }, average: { weeks: 1 }, label: { weeks: 1 } },
    { domain: { months: 6 }, average: { weeks: 1 }, label: { months: 1 } },
    { domain: { years: 1 }, average: { months: 1 }, label: { months: 1 } }
  ],
  [LoincCodingCode.bloodPressure]: [],
  [LoincCodingCode.bloodGlucoseCapillary]: []
};

export default class DataHubCubit extends Cubit<DataHubState<ChartDataItem>> {
  private storageKeys = {
    activeLoincCode: "activeLoincCode"
  };
  public activeObservations: ChartDataItem[] = [];
  public displayTimezone = getLocalTimeZone();
  public allDataFromDate = this.now.subtract({ months: 14 });
  public availableCharts: LoincCodingCode[] = [
    // first in list is active by default
    LoincCodingCode.weight,
    LoincCodingCode.hba1c,
    LoincCodingCode.waistCircumference,
    LoincCodingCode.stepsInDay
  ];

  constructor() {
    super({
      chartData: [],
      chartLabel: {
        domain: [],
        ticks: []
      }
    });

    void this.setActiveObservations({
      loincCode: this.initialState.loincCode
    });
  }

  get now() {
    return now(getLocalTimeZone());
  }

  get initialState(): {
    loincCode?: LoincCodingCode;
    activeTimeRange?: ChartTimeRange;
  } {
    const state = {
      loincCode: this.availableCharts[0],
      activeTimeRange: LoincCodeToTimeRangesRecord[this.availableCharts[0]]?.[0]
    };

    const cachedLoincCode = StorageController.getItem(
      this.storageKeys.activeLoincCode,
      sessionStorage
    );

    if (cachedLoincCode) {
      state.loincCode = cachedLoincCode as LoincCodingCode;
    }

    return state;
  }

  get userLanguage() {
    return getSupportedUserLanguage();
  }

  private dateStartOfLabel = (date: DateValue): DateValue => {
    const { label } = this.state.activeTimeRange ?? {};
    if (!label) {
      return date;
    }

    if (label.months === 1) {
      return startOfMonth(date);
    }

    if (label.weeks === 1) {
      return startOfWeek(date, "en-US");
    }

    if (label.days === 1) {
      return date.copy().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    if (label.hours === 1) {
      return date.copy().set({ minute: 0, second: 0, millisecond: 0 });
    }

    // eslint-disable-next-line no-console
    console.error("Cannot find start for label", {
      label: this.state.activeTimeRange?.label
    });
    return date;
  };

  dateLimitOfAverateMemoized = new Map<string, DateValue>();
  private dateLimitOfAverage = (
    date: DateValue,
    side: "end" | "start"
  ): DateValue => {
    const { average } = this.state.activeTimeRange ?? {};
    const key = `${date.toString()}-${side}-${average?.months}-${average?.weeks}-${average?.days}-${average?.hours}`;
    const memoized = this.dateLimitOfAverateMemoized.get(key);
    if (memoized) {
      return memoized;
    }

    if (!average) {
      this.dateLimitOfAverateMemoized.set(key, date.copy());
      return date;
    }

    let startofLimit: null | ((date: DateValue) => DateValue) = null;
    let endOfLimit: null | ((date: DateValue) => DateValue) = null;

    if (average.months === 1) {
      endOfLimit = endOfMonth;
      startofLimit = startOfMonth;
    }

    if (average.weeks === 1) {
      endOfLimit = (date: DateValue) => endOfWeek(date, "en-US");
      startofLimit = (date: DateValue) => startOfWeek(date, "en-US");
    }

    if (average.hours === 1) {
      endOfLimit = (date: DateValue) =>
        date.set({ minute: 59, second: 59, millisecond: 999 });
      startofLimit = (date: DateValue) =>
        date.set({ minute: 0, second: 0, millisecond: 0 });
    }

    if (startofLimit && endOfLimit) {
      const returnValue = side === "start" ? startofLimit(date) : endOfLimit(date);
      this.dateLimitOfAverateMemoized.set(key, returnValue.copy());
      return returnValue;
    }

    this.dateLimitOfAverateMemoized.set(key, date.copy());
    return date;
  };

  private readonly getTimeRangeDates = (): DateRange => {
    const { domain } = this.state.activeTimeRange ?? {};
    if (!domain) {
      throw new Error("Active time range or is not set");
    }

    const fromDate = this.dateLimitOfAverage(
      this.now.subtract(domain),
      "start"
    );

    return {
      fromDate,
      toDate: this.now
    };
  };

  public prepareDataItemForCache = (
    item: ChartDataItem
  ): ChartDateItemPrimitive => {
    return {
      ...item,
      date: item.date.toString()
    };
  };

  public parseItemFromCache = (item: ChartDateItemPrimitive): ChartDataItem => {
    return {
      ...item,
      date: parseDateTime(item.date)
    };
  };

  cachedKeys = new Set<string>();
  public clearApiCache = (loincCode?: LoincCodingCode) => {
    for (const key of this.cachedKeys) {
      // if a loinc code is set, only remove keys that include the loinc code
      if (loincCode && !key.includes(loincCode)) {
        continue;
      }

      StorageController.removeItem(key, memStorage);
    }
    this.cachedKeys.clear();
  };

  public readonly fetchObservations = async (options: {
    loincCodes: LoincCodingCode[];
    toDate: DateValue;
  }): Promise<ChartDataItem[]> => {
    // load from cache
    const day = today(getLocalTimeZone());
    const cacheKey = `datahub:${day.toString()}:${options.loincCodes.join("-")}`;
    const cached = StorageController.getItemParsed<ChartDateItemPrimitive[]>(
      cacheKey,
      memStorage
    );
    if (cached && cached.length > 0) {
      return cached.map(this.parseItemFromCache);
    }

    // fetch from server
    const response = await ObservationControllerService.getObservations(
      toCalendarDate(this.allDataFromDate).toString(),
      toCalendarDate(options.toDate).toString(),
      options.loincCodes
    );

    const { data } = response as unknown as {
      status: string;
      data: Observation[];
    };

    // prepare data for charts
    const customTypedObservations: ChartDataItem[] = data
      .map(this.parseObservationToChartDataItem)
      .filter(Boolean);

    const sortedObservations = customTypedObservations.toSorted((a, b) =>
      a.date.compare(b.date)
    );

    // save to cache
    StorageController.setItem(
      cacheKey,
      JSON.stringify(sortedObservations.map(this.prepareDataItemForCache)),
      memStorage
    );
    this.cachedKeys.add(cacheKey);

    return sortedObservations;
  };

  readonly setActiveObservations = async (options: {
    loincCode?: LoincCodingCode;
  }) => {
    if (!options.loincCode) {
      throw new Error("Loinc code is not set");
    }

    const currentTimeRange = this.state.activeTimeRange;

    let activeTimeRange = LoincCodeToTimeRangesRecord[options.loincCode]?.[0];

    // if no time ranges are available for the current loinc code, use the current one
    if (!activeTimeRange) {
      activeTimeRange = currentTimeRange;
    }

    this.patch({
      activeLoincCode: options.loincCode,
      activeTimeRange,
      chartData: undefined,
      chartLabel: undefined
    });
    this.activeObservations = [];

    StorageController.setItem(
      this.storageKeys.activeLoincCode,
      options.loincCode,
      sessionStorage
    );

    void this.updateChartAfterLoincCodeChange({
      loincCode: options.loincCode
    });
  };

  private readonly updateChartAfterLoincCodeChange = async (options: {
    loincCode: LoincCodingCode;
  }) => {
    try {
      let nos = Date.now();
      this.setAvailableTimeRanges();
      const { toDate } = this.getTimeRangeDates();
      nos = Date.now();
      const loincCodeGroup = LoincCodeGroups[options.loincCode];

      const observations = await this.fetchObservations({
        loincCodes: loincCodeGroup ?? [options.loincCode],
        toDate
      });
      nos = Date.now();

      this.activeObservations = observations;
      this.setActiveProgress();
      nos = Date.now();
      this.setChartData();
      nos = Date.now();
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  public parseObservationToChartDataItem = (
    obs: Observation
  ): ChartDataItem | null => {
    let value = obs.valueQuantity?.value;
    let unit = obs.valueQuantity?.unit ?? "";
    const date =
      obs.effectiveDateTime && parseDateMultiTry(obs.effectiveDateTime);

    // remove observations with no numeric value or date
    if (typeof value !== "number" || !date) {
      return null;
    }

    // convert units
    if (unit === "kg") {
      value = Math.round(kgToPounds(value));
      unit = translate("lbs");
    }

    if (unit === "cm") {
      value = Math.round(cmToInch(value));
      unit = translate("inches");
    }

    return {
      value,
      time: date.toDate(this.displayTimezone).getTime(),
      valueCalculation: "single",
      unit,
      date
    } satisfies ChartDataItem;
  };

  public setChartData = () => {
    const values = this.activeObservations;
    const averaged = this.groupChartDataByTimeRangeAverage(values);
    const label = this.generateLabels();

    this.patch({
      chartData: averaged,
      chartLabel: {
        domain: label.domain,
        ticks: label.ticks
      }
    });
  };

  getSampleValue = (values: ChartDataItem[]): ChartDataItem => {
    if (values.length === 0) {
      return {
        value: 0,
        unit: "",
        time: 0,
        date: now(getLocalTimeZone()),
        valueCalculation: "single"
      };
    }
    return values[0];
  };

  private readonly setAvailableTimeRanges = () => {
    if (!this.state.activeLoincCode) {
      throw new Error("Active loinc code is not set");
    }

    this.patch({
      availableTimeRanges:
        LoincCodeToTimeRangesRecord[this.state.activeLoincCode]
    });
  };

  private readonly setActiveProgress = () => {
    const { toDate, fromDate } = this.getTimeRangeDates();
    const { valuesInRange } = this.getValuesInDateRange(
      fromDate,
      toDate,
      this.activeObservations
    );
    // If there are less than 2 active observations, reset progress
    if (valuesInRange.length < 2) {
      this.patch({
        activeProgress: undefined
      });

      return;
    }

    const { unit } = this.getSampleValue(valuesInRange);
    const firstObservation = valuesInRange[0].value;
    const lastObservation = valuesInRange[valuesInRange.length - 1].value;

    let difference = lastObservation - firstObservation;

    if (unit === "steps") {
      const positiveObservationsInRange = valuesInRange.filter(
        (obs) => obs.value > 0
      );

      const positiveObservationsInRangeSum = positiveObservationsInRange.reduce(
        (acc, val) => acc + val.value,
        0
      );
      //for steps is not difference, it's the average
      difference =
        positiveObservationsInRangeSum / positiveObservationsInRange.length;
    }

    this.patch({
      activeProgress: {
        difference,
        unit
      }
    });
  };

  public readonly isSameTimeRange = (
    timeRange: DateDuration,
    activeTimeRange?: DateDuration
  ) => {
    const sameYears = activeTimeRange?.years === timeRange.years;
    const sameMonths = activeTimeRange?.months === timeRange.months;
    const sameWeeks = activeTimeRange?.weeks === timeRange.weeks;
    const sameDays = activeTimeRange?.days === timeRange.days;

    return sameYears && sameMonths && sameWeeks && sameDays;
  };

  public readonly setActiveInterval = (interval: DateDuration) => {
    const activeTimeRange = this.state.availableTimeRanges?.find(
      (availableTimeRange) => availableTimeRange.domain === interval
    );
    if (!activeTimeRange) {
      throw new Error("Active time range is not found");
    }

    this.patch({
      activeTimeRange,
      activeProgress: undefined
    });
    this.setActiveProgress();
    this.setChartData();
  };

  getValuesInDateRangeMemoized = new Map<
    string,
    ReturnType<typeof this.getValuesInDateRange>
  >();
  getValuesInDateRange = (
    startDate: DateValue,
    endDate: DateValue,
    values: ChartDataItem[]
  ): { averageValue: number | null; valuesInRange: ChartDataItem[] } => {
    const key = `${startDate.toString()}-${endDate.toString()}-${values.length}`;

    const memoized = this.getValuesInDateRangeMemoized.get(key);
    if (memoized) {
      return memoized;
    }

    const valuesInRange: ChartDataItem[] = [];
    for (const v of values) {
      if (
        typeof v.date === "object" &&
        typeof v.value === "number" &&
        v.date.compare(startDate) >= 0 &&
        v.date.compare(endDate) <= 0
      ) {
        valuesInRange.push(v);
      }
    }

    const total = valuesInRange.reduce((acc, v) => acc + v.value, 0);
    let averageValue =
      valuesInRange.length > 0 ? total / valuesInRange.length : null;

    if (averageValue !== null) {
      // limit to 5 decimal places, this also avoids floating point errors
      averageValue = parseFloat(averageValue.toFixed(5));
    }

    this.getValuesInDateRangeMemoized.set(key, { averageValue, valuesInRange });
    return { averageValue, valuesInRange };
  };

  public groupChartDataByTimeRangeAverage = (
    values: ChartDataItem[],
    startDate: DateValue = this.allDataFromDate,
    endDate: DateValue = this.dateLimitOfAverage(this.now, "end")
  ): ChartDataItem[] => {
    const array: ChartDataItem[] = [];
    const { average } = this.state.activeTimeRange ?? {};

    if (!average) {
      return this.getValuesInDateRange(startDate, endDate, values)
        .valuesInRange;
    }

    let date = this.dateLimitOfAverage(startDate, "start");

    if (average.months === 1 || average.weeks === 1 || average.days === 1) {
      date = date.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    } else if (average.hours === 1) {
      date = date.set({ minute: 0, second: 0, millisecond: 0 });
    }

    while (date.compare(endDate) <= 0) {
      let nextDate = this.dateLimitOfAverage(date, "end");
      nextDate = endDate.compare(nextDate) < 0 ? endDate : nextDate;

      const { averageValue, valuesInRange } = this.getValuesInDateRange(
        date,
        nextDate,
        values
      );

      if (averageValue !== null) {
        array.push({
          value: averageValue,
          unit: this.getSampleValue(valuesInRange).unit,
          valueCalculation: valuesInRange.length > 1 ? "average" : "single",
          time: date.toDate(this.displayTimezone).getTime(),
          date: date
        });
      }

      date = date.add(average);
    }

    return array;
  };

  generateLabels = () => {
    const timestamps = [];
    const { fromDate, toDate } = this.getTimeRangeDates();

    let countStartDate = this.dateStartOfLabel(fromDate);
    const { label } = this.state.activeTimeRange ?? { label: { months: 1 } };
    let maxTicks = 50;

    while (countStartDate.compare(this.now) <= 0 && maxTicks > 0) {
      timestamps.push(countStartDate.toDate(this.displayTimezone).getTime());
      countStartDate = this.dateStartOfLabel(countStartDate.add(label));
      maxTicks -= 1;
    }

    return {
      domain: [
        fromDate.toDate(this.displayTimezone).getTime(),
        toDate.toDate(this.displayTimezone).getTime()
      ],
      ticks: timestamps
    };
  };

  public tickFormatterAxisX = (time: number) => {
    if (!this.state.activeTimeRange) {
      throw new Error("Active time range is not set");
    }
    const tickCount = this.state.chartLabel?.ticks.length ?? 0;
    const { months, weeks, days, hours } = this.state.activeTimeRange.label;

    if (months === 1) {
      if (tickCount <= 7) {
        return new Date(time).toLocaleDateString(this.userLanguage, {
          month: "short"
        });
      } else {
        return new Date(time)
          .toLocaleDateString(this.userLanguage, {
            month: "short"
          })
          .slice(0, 1);
      }
    }

    if (weeks === 1) {
      return new Date(time).toLocaleDateString(this.userLanguage, {
        month: "short",
        day: "numeric"
      });
    }

    if (days === 1) {
      return new Date(time).toLocaleDateString(this.userLanguage, {
        day: "numeric"
      });
    }

    if (hours === 1) {
      return new Date(time).toLocaleTimeString(this.userLanguage, {
        hour: "numeric"
      });
    }

    return "";
  };

  public formatLabel = ({ payload }: { payload: ChartDataItem[] }) => {
    // eslint-disable-next-line @typescript-eslint/prefer-destructuring
    const { date, label } = payload[0];
    if (label) {
      return label;
    }
    const { average } = this.state.activeTimeRange ?? {};

    if (average?.months === 1) {
      return date
        .toDate(this.displayTimezone)
        .toLocaleDateString(this.userLanguage, { month: "short" });
    }

    if (average?.weeks === 1) {
      const from = date
        .toDate(this.displayTimezone)
        .toLocaleDateString(this.userLanguage, {
          month: "short",
          day: "numeric"
        });
      const to = date
        .add({ days: 6 })
        .toDate(this.displayTimezone)
        .toLocaleDateString(this.userLanguage, {
          month: "short",
          day: "numeric"
        });
      return translate("chart.label.week", { from, to });
    }

    if (average?.days === 1) {
      return date
        .toDate(this.displayTimezone)
        .toLocaleDateString(this.userLanguage, {
          month: "short",
          day: "numeric",
          hour: "numeric"
        });
    }

    if (average?.hours === 1) {
      return date
        .toDate(this.displayTimezone)
        .toLocaleDateString(this.userLanguage, {
          month: "short",
          day: "numeric",
          hour: "numeric"
        });
    }

    return date
      .toDate(this.displayTimezone)
      .toLocaleDateString(this.userLanguage, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
  };

  public formatValue = ({
    payload
  }: {
    payload: ChartDataItem[];
  }): ReactNode => {
    if (payload[0].valueString) {
      return payload[0].valueString;
    }

    if (payload[0].valueCalculation === "average") {
      return (
        <span>
          {Math.round(payload[0].value)} {payload[0].unit}{" "}
          <em>{translate(`chart.average`)}</em>
        </span>
      );
    }

    return `${payload[0].value} ${payload[0].unit}`;
  };

  public readonly getLoincCodesForUrl = (
    loincCode?: LoincCodingCode
  ): string => {
    if (!loincCode) {
      return "";
    }

    const loincCodeGroup = LoincCodeGroups[loincCode];

    return loincCodeGroup ? loincCodeGroup.join(",") : loincCode;
  };
}

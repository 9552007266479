import {
  CalendarDate,
  CalendarDateTime,
  toCalendarDateTime,
  ZonedDateTime
} from "@internationalized/date";

type AllowedDateTypes = CalendarDate | CalendarDateTime | ZonedDateTime;

export default function compareTime(
  date1: AllowedDateTypes,
  date2: AllowedDateTypes
) {
  const d1asCalendarDateTime = toCalendarDateTime(date1);
  const d2asCalendarDateTime = toCalendarDateTime(date2);

  const d1TimeMinute =
    d1asCalendarDateTime.hour * 60 + d1asCalendarDateTime.minute;
  const d2TimeMinute =
    d2asCalendarDateTime.hour * 60 + d2asCalendarDateTime.minute;

  const d1Time = d1TimeMinute * 60 + d1asCalendarDateTime.second;
  const d2Time = d2TimeMinute * 60 + d2asCalendarDateTime.second;

  return d1Time - d2Time;
}

import { keyframes } from "@emotion/css";
import styled from "@emotion/styled";

const SpinKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const EndSpinKeyframes = keyframes`
    0% {
      opacity: 0;
        transform: rotate(180deg);
    }
    100% {
      opacity: 1;
        transform: rotate(360deg);
    }
`;

const ImgWrap = styled.div`
  position: absolute;
  opacity: 0;
  transform: scale(var(--spinner-scale, 1));
  transition: opacity 0.3s ease-in-out;
  text-align: center;

  h3,
  p {
    font-family: var(--font-family);
    color: var(--Greys-White, #fff);
    letter-spacing: -0.36px;
    padding: 0 1.6rem;
  }

  h3 {
    font-weight: 500;
    margin-top: 0.8rem;
    font-size: 1.25rem;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    font-family: var(--font-family);
  }

  img {
    display: block;
    width: 4rem;
    height: 4rem;
    margin: 0 auto;
  }

  &.loading-progress img {
    animation: ${SpinKeyframes} 1.2s linear infinite;
  }

  &.loading-complete img {
    display: none;
    animation: ${EndSpinKeyframes} 0.6s ease-out forwards;
  }

  [data-status="success"] &.loading-complete,
  [data-status="loading"] &.loading-progress {
    opacity: 1;
    img {
      display: block;
    }
  }
`;

export default ImgWrap;

import { UpdateTaskRequest } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import Path from "src/constants/path";
import translate from "src/lib/translate";
import { onboardingCompletedTask } from "src/state/OnboardingBloc/OnboardingBloc";
import { taskManagementState } from "src/state/state";
import AnnouncementCard, {
  AnnouncementCardProps
} from "src/ui/components/AnnouncementCard/AnnouncementCard";
import { AppQueryPopupsController } from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";

//#region Styled Components
const DialogWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const DialogContent = styled.div`
  max-width: ${APP_CONTENT_WIDTH}px;
`;
//#endregion

const AnnouncementCardDialog: FC = () => {
  const [query] = useSearchParams();
  const screen = query.get("screen");

  const announcementCardProps = useMemo<
    AnnouncementCardProps | undefined
  >(() => {
    switch (screen) {
      case Path.myPlan:
        return {
          img: {
            src: "https://cdn.sanity.io/images/g38rxyoc/production/66be72dddc366094e748db83a2cc988cdd17e08b-500x500.gif",
            alt: ""
          },
          title: translate("announcementCard.title", { context: "myPlan" }),
          subtitle: translate("announcementCard.subtitle", {
            context: "myPlan"
          }),
          buttonText: translate("announcementCard.buttonText", {
            context: "myPlan"
          }),
          buttonAction: () => {}
        };

      case Path.labResults:
        return {
          img: {
            src: "https://cdn.sanity.io/images/g38rxyoc/production/b136ebaa193ecd75dfb6c89cfdf80558bfeaf08c-500x500.gif",
            alt: ""
          },
          title: translate("announcementCard.title", {
            context: "dataHub"
          }),
          subtitle: translate("announcementCard.subtitle", {
            context: "dataHub"
          }),
          buttonText: translate("announcementCard.buttonText", {
            context: "dataHub"
          }),
          buttonAction: () => {}
        };

      case Path.home:
        return {
          img: {
            src: "https://cdn.sanity.io/images/g38rxyoc/production/7fe15e88443e19b19e7e991f6ffb82ebd6da7842-500x500.gif",
            alt: ""
          },
          title: translate("announcementCard.title", { context: "home" }),
          subtitle: translate("announcementCard.subtitle", {
            context: "home"
          }),
          buttonText: translate("announcementCard.buttonText", {
            context: "home"
          }),
          buttonAction: () => {
            void taskManagementState
              .updateTaskStatus(
                onboardingCompletedTask,
                UpdateTaskRequest.status.COMPLETED
              )
              .then(() => {
                AppQueryPopupsController.closePopup();
              });
          }
        };

      default:
        return undefined;
    }
  }, [screen]);

  return (
    <>
      {announcementCardProps && (
        <DialogWrapper>
          <DialogContent>
            <AnnouncementCard {...announcementCardProps} />
          </DialogContent>
        </DialogWrapper>
      )}
    </>
  );
};

export default AnnouncementCardDialog;

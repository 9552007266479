import React, { FC, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { APP_BREAKPOINT_SM } from "src/constants/layout";
import { useBloc } from "@blac/react";
import { CarouselCubit } from "src/ui/components/AppointmentsList/components/Carousel/CarouselCubit";
import clsx from "clsx";

const Wrapper = styled.div`
  --spaceWidth: 6rem;
  --spaceWidthBottom: 5rem;

  &.pagination-hidden {
    --spaceWidthBottom: 8rem;
  }

  > * {
    pointer-events: all;
  }

  overflow-x: auto;
  margin-top: calc(var(--spaceWidth) * -1);
  margin-left: calc(var(--spaceWidth) * -1);
  margin-right: calc(var(--spaceWidth) * -1);
  margin-bottom: calc(var(--spaceWidthBottom) * -1);
  padding: 6rem;
  display: flex;
  gap: 1rem;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory;

  @media screen and (max-width: ${APP_BREAKPOINT_SM}px) {
    gap: 0.75rem;
  }
`;

const Carousel: FC<{
  isPaginationHidden?: boolean;
  children: ReactNode;
}> = ({ children, isPaginationHidden }) => {
  const [, { syncCarouselState, handleScroll, ref }] = useBloc(CarouselCubit);

  useEffect(() => {
    syncCarouselState();

    window.addEventListener("resize", syncCarouselState, { passive: true });

    return (): void => window.removeEventListener("resize", syncCarouselState);
  }, []);

  return (
    <Wrapper
      ref={ref}
      onScroll={handleScroll}
      className={clsx({
        "pagination-hidden": isPaginationHidden
      })}
    >
      {children}
    </Wrapper>
  );
};

export default Carousel;

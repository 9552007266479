import React from "react";

export default function IconCalendarSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#a-path-ics)">
        <path
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity=".6"
          strokeWidth="1.5"
          d="M10 5v5l3.33 1.67m5-1.67a8.33 8.33 0 1 1-16.66 0 8.33 8.33 0 0 1 16.66 0Z"
        />
      </g>
      <defs>
        <clipPath id="a-path-ics">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { ScheduleAppointmentTypes } from "molecule/scheduler/SchedulerBloc";
import IconAppointmentProfileHeart from "@9amhealth/shared/icons/IconAppointmentProfileHeart";
import IconAppointmentProfileStethoscope from "@9amhealth/shared/icons/IconAppointmentProfileStethoscope";
import IconAppointmentProfileHealthPlus from "@9amhealth/shared/icons/IconAppointmentProfileHealthPlus";
import IconAppointmentProfileHealthApple from "@9amhealth/shared/icons/IconAppointmentProfileHealthApple";
import React from "react";

const AppointmentIcon = ({ type }: { type: ScheduleAppointmentTypes }) => {
  switch (type) {
    case "SYNC_VISIT":
    case "SPECIALIST_VISIT":
      return <IconAppointmentProfileStethoscope />;
    case "PHARMACIST_VISIT":
    case "CCS_MED_CONSULT":
    case "LAB_RESULTS_REVIEW":
      return <IconAppointmentProfileHealthPlus />;
    case "DIETITIAN_VISIT":
      return <IconAppointmentProfileHealthApple />;
    case "COACHING_1ON1":
    default:
      return <IconAppointmentProfileHeart />;
  }
};

export default AppointmentIcon;

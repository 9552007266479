import {
  DataFieldAffixEl,
  DataFieldErrorEl,
  DataFieldLabelEl,
  DataFieldRow
} from "atom/datafield/dataFieldComponents";
import styled from "@emotion/styled";
import React from "react";
import {
  DateField,
  DateFieldStateContext,
  DateInput,
  DateSegment,
  DateValue
} from "react-aria-components";
import type { DateFieldState } from "react-stately";
import { z } from "zod";

const DateFieldEl = styled(DateField)`
  font-weight: 400;

  &:first-of-type {
    --data-field-border-radius: 0.5rem 0.5rem 0 0;
    --data-field-line-height: 0;
    margin-top: var(--data-field-first-margin, 0.5rem);
  }

  &:last-of-type {
    --data-field-border-radius: 0 0 0.5rem 0.5rem;
    margin-bottom: var(--data-field-last-margin, 0.5rem);
  }

  &:only-of-type {
    --data-field-border-radius: 0.5rem;
  }
`;

const DateInputEl = styled(DateInput)`
  display: flex;
  flex: 1;
  text-align: right;
  outline: none;
  border: none;
  background: transparent;
  padding: 1rem 0;
  margin: 0;
  min-width: 0;
  width: auto;
  justify-content: flex-end;
  width: fit-content;
  white-space: nowrap;
  forced-color-adjust: none;

  &[data-focus-within] {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: -1px;
  }
`;

const DateSegmentEl = styled(DateSegment)`
  --highlight-background: #a6c6ff;
  --highlight-foreground: #000;
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;
  color: var(--text-color);

  &[data-type="literal"] {
    padding: 0;
  }

  &[data-placeholder] {
    color: #848484;
    font-style: italic;
  }

  &:focus {
    color: var(--highlight-foreground);
    background: var(--highlight-background);
    outline: none;
    border-radius: 4px;
    caret-color: transparent;
  }
`;

export interface DataFieldDateProps
  extends Omit<React.ComponentProps<typeof DateField>, "ref"> {
  label?: string;
  affix?: React.ReactNode;
  onDateChange?: (date: DateValue | null) => void;
  errorParser?: (props: DataFieldDateProps) => string;
  error?: string | z.ZodIssue;
  // ref will be ignored in props, but it's needed for React.forwardRef
  ref?: React.Ref<DateFieldState | null>;
}

function DateFieldContextHandler(props: {
  onDateChange?: (date: DateValue | null) => void;
  initialValue?: DateValue | null;
}) {
  const state = React.useContext(DateFieldStateContext);
  const lastValue = React.useRef<DateValue | undefined>(
    props.initialValue ?? undefined
  );

  React.useEffect(() => {
    if (props.onDateChange && state && state.value !== lastValue.current) {
      props.onDateChange(state.value);
    }
  }, [state?.value, props.onDateChange, lastValue.current]);
  return null;
}

const DateFieldRefHandler = React.forwardRef(function DFRH(
  _props,
  ref?: React.Ref<DateFieldState | null>
) {
  const state = React.useContext(DateFieldStateContext);
  React.useImperativeHandle(ref, () => state, [state]);
  return null;
});

function DataFieldDateNoRef(
  props: DataFieldDateProps,
  ref?: React.Ref<DateFieldState>
) {
  const {
    label,
    affix,
    onDateChange,
    error,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    ...dateFieldProps
  } = props;
  const errorString = errorParser(props);

  return (
    <DateFieldEl
      {...dateFieldProps}
      ref={null}
      isInvalid={Boolean(error)}
      className="data-field"
    >
      <DataFieldRow className="data-field-row">
        <DataFieldLabelEl className="body1">{label}</DataFieldLabelEl>
        <DateInputEl className="body1" data-has-affix={Boolean(affix)}>
          {(segment) => <DateSegmentEl segment={segment} />}
        </DateInputEl>
        {affix && (
          <DataFieldAffixEl
            className="body1 data-field-affix"
            slot="description"
          >
            {affix}
          </DataFieldAffixEl>
        )}
      </DataFieldRow>
      <DataFieldErrorEl className={"little1 data-field-error"}>
        {errorString}
      </DataFieldErrorEl>
      <DateFieldContextHandler
        onDateChange={onDateChange}
        initialValue={dateFieldProps.defaultValue}
      />
      <DateFieldRefHandler ref={ref} />
    </DateFieldEl>
  );
}

const DataFieldDate = React.forwardRef(
  DataFieldDateNoRef
) as typeof DataFieldDateNoRef;
export default DataFieldDate;

import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import React from "react";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";

const DebugAppointmentScheduler = () => {
  const [{ scheduledAppointments }, { loadAppointments }] =
    useBloc(AppointmentsBloc);
  return (
    <div>
      <Button
        size="medium"
        onPress={() => {
          AppQueryPopupsController.openPopup(AppPopup.scheduleAppointment, {
            additionalParameters: {
              type: "SYNC_VISIT"
            }
          });
        }}
      >
        schedule sync visit
      </Button>
      <hr />

      <Button
        size="medium"
        onPress={() => {
          void loadAppointments();
        }}
      >
        Reload
      </Button>
      <hr />
      {scheduledAppointments.map((appointment) => (
        <div key={appointment.id}>
          <hr />
          <b>{appointment.id}</b>
          <ul>
            <li>type: {appointment.type}</li>
            <li>start: {appointment.start}</li>
            <li>end: {appointment.end}</li>
            <li>participants: {appointment.participants.length}</li>
          </ul>
          <Button
            size="small"
            onPress={() => {
              AppQueryPopupsController.openPopup(AppPopup.scheduleAppointment, {
                additionalParameters: {
                  reschedule: appointment.id
                }
              });
            }}
          >
            reschedule
          </Button>
        </div>
      ))}
    </div>
  );
};

export default DebugAppointmentScheduler;

import { Cubit } from "blac";
import { TranslationKey } from "src/types/translationKey";

export interface BlockingLoadingOverlayErrorProps {
  title?: string;
  message?: string;
  retry?: () => void;
  onCancel?: () => void;
}

export interface BlockingLoadingOverlayState {
  status: "error" | "loading" | "none" | "success";
  error?: BlockingLoadingOverlayErrorProps;
  bg: "solid" | "transparent" | "branded" | "partner";
  fadeIn: boolean;
  title?: string | TranslationKey;
  message?: string | TranslationKey;
}

export class BlockingLoadingOverlayBloc extends Cubit<BlockingLoadingOverlayState> {
  constructor() {
    super({
      status: "none",
      bg: "branded",
      fadeIn: true
    });
  }
  loadingCounter = 0;

  public readonly endLoading = () => {
    this.loadingCounter = Math.max(this.loadingCounter - 1, 0);
    if (this.loadingCounter === 0) {
      this.emit({
        ...this.state,
        status: "none",
        title: "",
        message: ""
      });
    }
  };

  public readonly loadingSuccess = (options: {
    title?: string | TranslationKey;
    message?: string | TranslationKey;
  } = {}) => {
    this.emit({
      ...this.state,
      status: "success",
      title: options.title ?? "",
      message: options.message ?? ""
    });

    let timeout = 1400;
    if (options.title) {
      timeout += 600;
    }
    if (options.message) {
      timeout += 600;
    }

    setTimeout(() => {
      this.endLoading();
    }, timeout);
  };

  public readonly loadingError = (error?: BlockingLoadingOverlayErrorProps) => {
    this.loadingCounter = Math.max(this.loadingCounter - 1, 0);
    this.emit({
      ...this.state,
      status: "error",
      error,
      title: error?.title ?? "",
      message: error?.message ?? ""
    });
  };

  public readonly startLoading = (
    options: {
      bg?: BlockingLoadingOverlayState["bg"];
      fadeIn?: boolean;
      title?: string | TranslationKey;
      message?: string | TranslationKey;
    } = {}
  ) => {
    this.loadingCounter++;

    this.emit({
      ...this.state,
      error: undefined,
      status: "loading",
      bg: options.bg ?? "branded",
      fadeIn: options.fadeIn ?? true,
      title: options.title ?? "",
      message: options.message ?? ""
    });
    return [this.loadingSuccess, this.loadingError];
  };

  public readonly callErrorRetry = () => {
    if (this.state.error?.retry) {
      this.state.error.retry();
    }
  };

  public readonly callOnCancel = () => {
    if (this.state.error?.onCancel) {
      this.state.error.onCancel();
    }
  };
}

const BlockingLoadingOverlayController = new BlockingLoadingOverlayBloc();
export default BlockingLoadingOverlayController;

import { useBloc } from "@blac/react";
import useEmblaCarousel from "embla-carousel-react";
import React, { CSSProperties, useContext, useEffect, useRef } from "react";
import {
  PickerItemValidCheck,
  PickerWheelItemBloc,
  type SlideItemProps
} from "./PickerWheelItemBloc";
import { WheelPickerContext } from "./WheelPickerContext";

interface PropType {
  loop?: boolean;
  name: string;
  slides: SlideItemProps[];
  alignContent?: "left" | "right" | "center";
  perspective?: "left" | "right";
  value?: string;
  validCheck?: PickerItemValidCheck;
}

const PickerItem: React.FC<PropType> = (props) => {
  const {
    value,
    perspective,
    slides,
    loop = false,
    alignContent = "center",
    validCheck
  } = props;
  const contextId = useContext(WheelPickerContext);
  const [itemState, itemBloc] = useBloc(PickerWheelItemBloc, {
    props: {
      name: props.name,
      value,
      slides,
      loop,
      validCheck,
      pickerId: contextId.id
    }
  });

  useEffect(() => {
    itemBloc.setSlides(slides);
  }, [slides]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop,
    axis: "y",
    dragFree: true,
    containScroll: false,
    watchResize: false,
    watchSlides: true
  });

  const rootNodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    itemBloc.emblaApi = emblaApi;
    itemBloc.setSlideReady();
    itemBloc.addResizeObserver();
    return () => {
      itemBloc.removeResizeObserver();
    };
  }, [emblaApi, emblaRef]);

  return (
    <div
      className="embla-picker"
      style={
        {
          "--item-width": `${itemBloc.spaceRequired}px`
        } as CSSProperties
      }
    >
      <div className="embla-picker__scene" ref={rootNodeRef}>
        <div
          className={`embla-picker__viewport embla-picker__viewport--perspective-${perspective}
          embla-picker__viewport--align-${alignContent}`}
          ref={emblaRef}
        >
          <div
            className="embla-picker__container"
            style={itemState.wheelReady ? {} : { transform: "none" }}
          >
            {itemState.slides.map((slide) => (
              <div
                className="embla-picker__slide"
                key={`${props.name}-${slide.label}`}
                style={
                  itemState.wheelReady
                    ? {}
                    : { position: "static", transform: "none" }
                }
              >
                <span>{slide.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickerItem;
